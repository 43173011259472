<!-- navbar desktop -->
<nav
  *ngIf="auth.validaLogin() && rotaAtual != '/login'"
  class="navbar navbar-expand-md navbar-light navbar-static fixed-top mb-5 z-10"
>
  <!-- Header with logos -->
  <div class="navbar-header navbar-dark d-none d-md-flex align-items-md-center">
    <div
      class="navbar-brand navbar-brand-md p-0"
      *ngIf="
        localStorage.TipoUsuario != '1' && localStorage.TipoUsuario != '10'
      "
    >
      <a routerLink="home" class="d-inline-block ml-5">
        <img
          src="assets\img\elogica-gente.png"
          alt="Elógica Gente"
          style="height: 35px"
        />
      </a>
    </div>

    <div class="navbar-brand navbar-brand-xs p-0">
      <a routerLink="home" class="d-inline-block">
        <img
          src="assets\img\icon-elo.png"
          alt="Elógica Gente"
          style="height: 30px"
        />
      </a>
    </div>
  </div>
  <!-- /header with logos -->
  <!-- Navbar content -->
  <div class="collapse navbar-collapse">
    <ul
      class="navbar-nav mr-md-auto"
      *ngIf="
        localStorage.TipoUsuario != '1' && localStorage.TipoUsuario != '10'
      "
    >
      <li class="nav-item">
        <a
          class="nav-link navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block"
          id="botaoInibirToogle"
        >
          <i class="icon-paragraph-justify3"></i>
        </a>
      </li>
      <li class="nav-item" style="display: none">
        <a
          class="nav-link navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block"
          id="botaoInibirToogleSide"
        >
        </a>
      </li>
    </ul>

    <ul
      class="navbar-nav mr-md-auto"
      *ngIf="
        localStorage.TipoUsuario == '1' || localStorage.TipoUsuario == '10'
      "
    >
      <li class="nav-item">
        <a class="d-inline-block">
          <img
            src="assets\img\elogica-gente.png"
            alt="Elógica Gente"
            style="height: 30px"
          />
        </a>
      </li>
    </ul>

    <ul class="navbar-nav">
      <!-- Rotina da Filial-->
      <!-- <li class="nav-item mt-2" *ngIf="localStorage.Filiais == 1">
				<p>{{filial[0].Codigo}} - {{filial[0].Nome}}</p>
			</li> -->
      <li class="nav-item dropdown d-flex align-items-center">
        <p class="m-0" *ngIf="localStorage.Filiais.length == 1">
          {{ localStorage.FilialAtual }} - {{ localStorage.NomeFilialAtual }}
        </p>

        <a
          class="navbar-nav-link dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
          *ngIf="localStorage.Filiais.length > 1"
          >{{ localStorage.FilialAtual }} -
          {{ localStorage.NomeFilialAtual }}</a
        >
        <div
          class="dropdown-menu dropdown-menu-right"
          style="overflow-y: auto; max-height: 400px"
          *ngIf="localStorage.Filiais.length > 1"
        >
          <a
            class="dropdown-item"
            *ngFor="let fil of localStorage.Filiais"
            (click)="alterarFilial(fil.Codigo, fil.Nome)"
            >{{ fil.Codigo }} - {{ fil.Nome }}</a
          >
        </div>
      </li>
      <!-- Fim da Rotina da Filial-->
      <li class="nav-item dropdown" *ngIf="listaMensagem.length != 0">
        <a
          (click)="montarListaAoClicar()"
          class="navbar-nav-link dropdown-toggle caret-0"
          data-toggle="dropdown"
        >
          <i class="icon-envelop3"></i>
          <span class="d-md-none ml-2">Avisos</span>
          <span
            *ngIf="montarVisualizado()"
            class="badge badge-pill bg-warning-400 ml-auto ml-md-0"
            >{{ contadorVizualizada() }}</span
          >
        </a>

        <div
          class="dropdown-menu dropdown-menu-right dropdown-content wmin-md-350"
        >
          <div class="dropdown-content-header">
            <span class="font-weight-semibold">Avisos</span>
          </div>

          <div class="dropdown-content-body dropdown-scrollable">
            <ul class="media-list">
              <li
                class="media"
                style="cursor: pointer"
                *ngFor="let aviso of listaMensagem"
              >
                <div class="media-body" (click)="obterMensagem(aviso)">
                  <div class="media-title">
                    <a>
                      <i class="icon-user mr-1"></i>
                      <span class="font-weight-semibold">{{
                        aviso.Usuario
                      }}</span>
                    </a>
                  </div>
                  <span class="text-muted mensagem-modal">{{
                    aviso.Mensagem
                  }}</span>
                </div>
              </li>
            </ul>
          </div>

          <div class="dropdown-content-footer justify-content-center p-0"></div>
        </div>
      </li>

      <li class="nav-item dropdown dropdown-user">
        <a
          href=""
          class="navbar-nav-link d-flex align-items-center dropdown-toggle"
          data-toggle="dropdown"
        >
          <!-- <img
            src="assets/img/avatar-masculino.svg"
            class="rounded-circle mr-2"
            height="34"
            alt="Avatar"
          /> -->
          <i class="icon-user mr-2" height="34" alt="Avatar Usuário"></i>
          <span class="text-capitalize">{{ localStorage.NomeUsuario }}</span>
        </a>

        <div class="dropdown-menu dropdown-menu-right">
          <!-- <a routerLink="profile" class="dropdown-item"><i class="icon-user"></i> Perfil</a> -->
          <!-- <a href="" class="dropdown-item"><i class="icon-comment-discussion"></i> Notificações <span class="badge badge-pill bg-indigo-400 ml-auto">17</span></a> -->
          <!-- <div class="dropdown-divider"></div> -->
          <!-- <a href="" class="dropdown-item"><i class="icon-cog5"></i> Configurações</a> -->
          <a
            *ngIf="localStorage.TipoUsuario == '1'"
            (click)="retornarPerfil()"
            class="dropdown-item"
            ><i class="icon-profile"></i> Perfil</a
          >
          <a
            href=""
            data-toggle="modal"
            data-target="#setSenhaModal"
            class="dropdown-item"
            ><i class="icon-pencil"></i> Alterar Senha</a
          >
          <a routerLink="" class="dropdown-item" (click)="sair()"
            ><i class="icon-switch2"></i> Sair</a
          >
        </div>
      </li>
    </ul>
  </div>
  <!-- /navbar content -->
</nav>
<!-- /navbar desktop -->
<!-- navbar mobile -->

<nav
  *ngIf="auth.validaLogin() && rotaAtual != '/login'"
  class="navbar navbar-expand-sm d-md-none navbar-light bg-light"
>
  <button
    *ngIf="localStorage.TipoUsuario != '1' && localStorage.TipoUsuario != '10'"
    class="navbar-toggler sidebar-mobile-main-toggle"
    type="button"
    aria-label="Toggle navigation user"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <a routerLink="home" class="navbar-brand mr-0" href="">
    <img
      src="assets\img\elogica-gente.png"
      alt="Elógica Gente"
      style="height: 30px"
    />
  </a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarNavDropdown"
    aria-controls="navbarNavDropdown"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="icon-user"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav">
      <p
        class="mt-1 text-center font-weight-semibold"
        *ngIf="localStorage.Filiais.length == 1"
      >
        {{ localStorage.FilialAtual }} -
        {{
          localStorage.NomeFilialAtual.length > 20
            ? localStorage.NomeFilialAtual.substr(0, 24) + "..."
            : localStorage.NomeFilialAtual
        }}
      </p>

      <li class="nav-item dropdown">
        <a
          class="navbar-nav-link dropdown-toggle m-0"
          href=""
          id="navbarDropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          *ngIf="localStorage.Filiais.length > 1"
        >
          Empresa Filial
        </a>
        <div
          class="dropdown-menu"
          aria-labelledby="navbarDropdownMenuLink"
          *ngIf="localStorage.Filiais.length > 1"
        >
          <a
            href=""
            class="dropdown-item"
            *ngFor="let fil of localStorage.Filiais"
            (click)="alterarFilial(fil.Codigo, fil.Nome)"
            >{{ fil.Codigo }} - {{ fil.Nome }}</a
          >
        </div>
      </li>

      <li class="nav-item dropdown">
        <a
          href=""
          class="navbar-nav-link dropdown-item dropdown-toggle m-0"
          data-toggle="dropdown"
        >
          <!-- <img
            src="assets/img/avatar-masculino.svg"
            class="rounded-circle mr-2"
            height="34"
            alt="Avatar Usuário"
          /> -->
          <i class="icon-user mr-2" height="34" alt="Avatar Usuário"></i>
          <span class="text-capitalize">{{ localStorage.NomeUsuario }}</span>
        </a>

        <div class="dropdown-menu dropdown-menu-right">
          <!-- <a routerLink="profile" class="dropdown-item"><i class="icon-user"></i> Perfil</a> -->
          <!-- <a href="" class="dropdown-item"><i class="icon-comment-discussion"></i> Notificações <span class="badge badge-pill bg-indigo-400 ml-auto">17</span></a> -->
          <!-- <div class="dropdown-divider"></div> -->
          <!-- <a href="" class="dropdown-item"><i class="icon-cog5"></i> Configurações</a> -->
          <a
            *ngIf="localStorage.TipoUsuario == '1'"
            (click)="retornarPerfil()"
            class="dropdown-item"
            ><i class="icon-profile"></i> Perfil</a
          >
          <a
            href=""
            data-toggle="modal"
            data-target="#setSenhaModal"
            class="dropdown-item"
            ><i class="icon-pencil"></i> Alterar Senha</a
          >
          <a routerLink="" class="dropdown-item" (click)="sair()"
            ><i class="icon-switch2"></i> Sair</a
          >
        </div>
      </li>
    </ul>
  </div>
</nav>
<!-- /navbar mobile -->
<!-- Modal para alterar Senha  -->
<div
  *ngIf="auth.validaLogin()"
  class="modal fade"
  id="setSenhaModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Alterar Senha</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 mb-1 form-group">
            <label class="justify-content-start">Senha atual</label>
            <input
              type="password"
              [(ngModel)]="senhaAtual"
              class="form-control"
              name="senhaAtual"
              placeholder="Insira sua senha atual"
            />
          </div>
          <div class="col-12 mb-1 form-group">
            <label class="justify-content-start">Nova senha</label>
            <input
              type="password"
              [(ngModel)]="novaSenha"
              class="form-control"
              name="novaSenha"
              placeholder="Insira sua nova senha"
            />
          </div>
          <div class="col-12 mb-1 form-group">
            <label class="justify-content-start">Confirmar senha</label>
            <input
              type="password"
              [(ngModel)]="confirmarSenha"
              class="form-control"
              name="confirmarSenha"
              placeholder="Confirme sua nova senha"
              #confirmarSenhaInput="ngModel"
              (focusout)="confirmarSenhaInput.control.markAsTouched()"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                novaSenha != null &&
                novaSenha != '' &&
                novaSenha != confirmarSenha &&
                confirmarSenhaInput.touched
              "
              >As senhas não conferem</span
            >
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          id="btn-close"
          class="btn btn-danger branco"
          (click)="limparCamposSenha()"
          data-dismiss="modal"
        >
          Cancelar
        </button>
        <button
          type="button"
          class="btn btn-primary branco"
          (click)="AlterarSenha()"
        >
          Confirmar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- <div id="empfil-mobile">
	<li class="nav-item dropdown" *ngIf="localStorage.Filiais > 1">
		<a href="" class="navbar-nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false">{{localStorage.FilialAtual}} - {{localStorage.NomeFilialAtual}}</a>
		<div class="dropdown-menu dropdown-menu-right"  style="overflow-y:auto; max-height: 400px;">
			<a href="" class="dropdown-item" *ngFor="let fil of filial" (click)="alterarFilial(fil.Codigo,fil.Nome)">{{fil.Codigo}} - {{fil.Nome}}</a>
		</div>
	</li>
</div> -->
