<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<ngx-spinner name="modalAnexo" bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<ng-container *ngIf="modal === false">
  <label *ngIf="!listaProcessos" class="font-weight-semibold"
    >Anexos <small *ngIf="opcional">(opcional)</small>
  </label>
  <br *ngIf="!listaProcessos" />

  <div class="d-flex align-items-center justify-content-start">
    <button
      type="button"
      class="btn btn-primary rounded-right-0"
      data-toggle="modal"
      data-target="#exampleModal"
      [ngClass]="{
        'button-disabled': !compChave || notHaveCpf || listaProcessos
      }"
    >
      <i class="fa fa-file"></i>
    </button>

    <button type="button" class="btn btn-light my-auto rounded-left-0 bg-light" disabled>
      <div *ngIf="compChave && !notHaveCpf">
        <span class="text-muted" *ngIf="listaAnexo.length == 0"
          >Não há nenhum arquivo anexado. Total de arquivos esperados:
          {{ listaTipoArquivo.length }}. <br
        /></span>
        <span class="text-muted" *ngIf="listaAnexo.length > 0"
          >Há {{ listaAnexo.length }} de {{ documentosPendentes.length }} arquivo(s) anexado(s).
        </span>
        <i
          *ngIf="localStorage.TipoUsuario == '1' && mensagemPendencia"
          class="fa fa-exclamation-circle alert-warning rounded-circle"
        ></i>
      </div>
      <span class="text-muted" *ngIf="!compChave || notHaveCpf"
        >Você precisa preencher o campo CPF antes de anexar arquivos.</span
      >
    </button>
  </div>

  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLabel">
            Anexos <span *ngIf="nomeUsuario">- {{ nomeUsuario }}</span
            ><small *ngIf="opcional">(opcional)</small>
          </h4>

          <button
            type="button"
            class="d-none"
            id="botaoAnexoFechar"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
          <button type="button" class="close" (click)="concluirModificacoes()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p *ngIf="mensagemPendencia && perfil" class="text-center alert-warning p-2 mb-4 rounded">
            <i class="fa fa-exclamation-circle fa-3x mb-4"></i><br />
            {{ mensagemPendencia }}
          </p>
          <form #anexo="ngForm" [formGroup]="formAnexo" (ngSubmit)="submitAnexo(anexo)" class="row">
            <div class="col-3" *ngIf="permiteMudanca() ? true : !perfil">
              <label for="addFile" class="btn btn-primary w-100">{{
                qtdArquivos == 0 ? 'Anexar Arquivo' : qtdArquivos + ' Arquivo(s)'
              }}</label>
              <input
                #inputAdicionar
                type="file"
                id="addFile"
                accept="image/jpeg,application/pdf"
                (change)="onArquivoChange($event)"
                (click)="limpar($event)"
                style="display: none"
              />
            </div>
            <div class="col-9" *ngIf="formAnexo.value.arquivos.length > 0">
              <select
                class="form-control"
                formControlName="tipoArquivo"
                [ngClass]="formAnexo.invalid ? 'is-invalid' : ''"
              >
                <option value="">Tipo de arquivo</option>
                <option
                  *ngFor="let tipoArquivo of listaTipoArquivo"
                  value="{{ tipoArquivo.Codigo }}"
                >
                  {{ tipoArquivo.Descricao }}
                </option>
              </select>
              <span class="form-text text-danger" *ngIf="formAnexo.invalid">
                Campo obrigatório
              </span>
            </div>

            <div *ngIf="permiteMudanca() ? true : !perfil" class="col-md-12 form-group">
              <label class="font-weight-semibold">Comentários</label>
              <div class="text-right">
                <textarea
                  type="text"
                  formControlName="comentario"
                  class="form-control col-12"
                  placeholder="Digite sua mensagem aqui"
                  maxlength="100"
                ></textarea>
                <small
                  [ngStyle]="
                    formAnexo.get('comentario').value.length >= 90 ? { color: 'red' } : null
                  "
                  >{{ formAnexo.get('comentario').value.length }}/100</small
                >
              </div>
              <div class="row justify-content-end">
                <div class="col-3" *ngIf="!editado && formAnexo.value.arquivos.length > 0">
                  <button type="button" class="btn btn-danger w-100" (click)="limparCampos()">
                    Limpar
                  </button>
                </div>
                <div class="col-3" *ngIf="!editado && formAnexo.value.arquivos.length > 0">
                  <button
                    type="submit"
                    class="btn btn-primary w-100"
                    [disabled]="formAnexo.get('arquivos').value.length == 0 || formAnexo.invalid"
                  >
                    Adicionar
                  </button>
                </div>
              </div>
              <!-- <div class="d-flex justify-content-end" *ngIf="!editado">
                <button
                  type="submit"
                  class="btn btn-primary my-2 col-md-2"
                  [disabled]="
                    formAnexo.get('arquivos').value.length == 0 ||
                    formAnexo.get('comentario').value == '' ||
                    formAnexo.invalid
                  "
                >
                  Adicionar
                </button>
              </div> -->
              <div class="d-flex justify-content-end" *ngIf="editado">
                <button
                  type="button"
                  (click)="alterarAnexo()"
                  class="btn btn-primary m-1 mt-2 col-md-2"
                  [disabled]="
                    (formAnexo.get('arquivos').value.length == 0 &&
                      formAnexo.get('comentario').value == '') ||
                    formAnexo.invalid
                  "
                >
                  Alterar
                </button>
                <button
                  type="button"
                  (click)="limparCampos()"
                  class="btn btn-secondary my-1 mt-2 col-md-2 mr-0"
                >
                  Cancelar
                </button>
              </div>
            </div>
          </form>

          <div *ngIf="listaAnexo.length > 0">
            <h5 class="border-top pt-3 mt-2">Lista de Anexos</h5>
            <ng-container *ngFor="let anexos of listaAnexo; let i = index">
              <div
                class="p-2 hoverable border rounded-lg"
                [ngClass]="i + 1 != listaAnexo.length ? 'mb-2' : ''"
                *ngFor="let anexo of anexos.Arquivos; let j = index"
              >
                <div *ngIf="anexo.ArquivoBase64">
                  <div class="d-flex align-items-center">
                    <img
                      [src]="
                        anexo.Extensao == 'jpeg'
                          ? '../../../assets/img/imgIcon.png'
                          : '../../../assets/img/pdfIcon.png'
                      "
                      height="55"
                      width="55"
                      alt=""
                    />
                    <div class="col-5">
                      <p class="font-weight-semibold mb-0">
                        {{ buscarNomeArquivo(anexo) }}
                      </p>
                      <p *ngIf="anexos.ArquivoSalvo" style="font-size: 10px">
                        {{ anexos.Arquivos[0].DataCadastro.ToDate }}
                      </p>
                      <p class="mb-0">{{ anexos.Comentario }}</p>
                    </div>

                    <div
                      class="d-flex justify-content-end ml-auto p-0"
                      *ngIf="
                        localStorage.TipoUsuario == '5' || localStorage.TipoUsuario == '10'
                          ? true
                          : localStorage.TipoUsuario == '1'
                          ? permiteMudanca(anexos.Arquivos[0].DescricaoTRG)
                          : false
                      "
                    >
                      <button
                        type="button"
                        class="btn bg-primary btn-sm m-1"
                        (click)="downloadAnexo(anexo)"
                        title="Download"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                      <!-- <button
                        type="button"
                        class="btn bg-primary btn-sm m-1"
                        (click)="editarAnexo(anexos, i)"
                        title="Editar"
                      >
                        <i class="fa fa-pencil"></i>
                      </button> -->
                      <button
                        type="button"
                        class="btn btn-danger btn-sm m-1"
                        (click)="excluirAnexo(anexos)"
                        title="Excluir"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div *ngIf="loading && listaTipoArquivo.length === 0">
            <div class="d-flex col-md-12 form-group p-4 justify-content-center">
              <i class="fa fa-spin fa-spinner fa-2x"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="modal === true">
  <div class="modal-header">
    <h4 class="modal-title" id="exampleModalLabel">
      Anexos <span *ngIf="nomeUsuario">- {{ nomeUsuario }}</span
      ><small *ngIf="opcional">(opcional)</small>
    </h4>

    <button
      type="button"
      class="d-none"
      id="botaoAnexoFechar"
      data-dismiss="modal"
      aria-label="Close"
    ></button>
    <button type="button" class="close" (click)="concluirModificacoes()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p *ngIf="mensagemPendencia && perfil" class="text-center alert-warning p-2 mb-4 rounded">
      <i class="fa fa-exclamation-circle fa-3x mb-4"></i><br />
      {{ mensagemPendencia }}
    </p>
    <form
      #anexo="ngForm"
      [formGroup]="formAnexo"
      (ngSubmit)="submitAnexo(anexo)"
      class="row d-none"
      [class.d-none]="!podeEditar"
    >
      <div class="col-3" *ngIf="permiteMudanca() ? true : !perfil">
        <label for="addFile" class="btn btn-primary w-100">{{
          qtdArquivos == 0 ? 'Anexar Arquivo' : qtdArquivos + ' Arquivo(s)'
        }}</label>
        <input
          #inputAdicionar
          type="file"
          id="addFile"
          accept="image/jpeg,application/pdf"
          (change)="onArquivoChange($event)"
          (click)="limpar($event)"
          style="display: none"
        />
      </div>
      <div class="col-9" *ngIf="formAnexo.value.arquivos.length > 0">
        <select
          class="form-control"
          formControlName="tipoArquivo"
          [ngClass]="formAnexo.invalid ? 'is-invalid' : ''"
        >
          <option value="">Tipo de arquivo</option>
          <option *ngFor="let tipoArquivo of listaTipoArquivo" value="{{ tipoArquivo.Codigo }}">
            {{ tipoArquivo.Descricao }}
          </option>
        </select>
        <span class="form-text text-danger" *ngIf="formAnexo.invalid"> Campo obrigatório </span>
      </div>

      <div *ngIf="permiteMudanca() ? true : !perfil" class="col-md-12 form-group">
        <!-- <label class="font-weight-semibold">Comentários</label>
        <div class="text-right">
          <textarea
            type="text"
            formControlName="comentario"
            class="form-control col-12"
            placeholder="Digite sua mensagem aqui"
            maxlength="100"
          ></textarea>
          <small
            [ngStyle]="formAnexo.get('comentario').value.length >= 90 ? { color: 'red' } : null"
            >{{ formAnexo.get('comentario').value.length }}/100</small
          >
        </div> -->
        <div class="row justify-content-end">
          <div class="col-3" *ngIf="!editado && formAnexo.value.arquivos.length > 0">
            <button type="button" class="btn btn-danger w-100" (click)="limparCampos()">
              Limpar
            </button>
          </div>
          <div class="col-3" *ngIf="!editado && formAnexo.value.arquivos.length > 0">
            <button
              type="submit"
              class="btn btn-primary w-100"
              [disabled]="formAnexo.get('arquivos').value.length == 0 || formAnexo.invalid"
            >
              Adicionar
            </button>
          </div>
        </div>
        <!-- <div class="d-flex justify-content-end" *ngIf="!editado">
                <button
                  type="submit"
                  class="btn btn-primary my-2 col-md-2"
                  [disabled]="
                    formAnexo.get('arquivos').value.length == 0 ||
                    formAnexo.get('comentario').value == '' ||
                    formAnexo.invalid
                  "
                >
                  Adicionar
                </button>
              </div> -->
        <div class="d-flex justify-content-end" *ngIf="editado">
          <button
            type="button"
            (click)="alterarAnexo()"
            class="btn btn-primary m-1 mt-2 col-md-2"
            [disabled]="
              (formAnexo.get('arquivos').value.length == 0 &&
                formAnexo.get('comentario').value == '') ||
              formAnexo.invalid
            "
          >
            Alterar
          </button>
          <button
            type="button"
            (click)="limparCampos()"
            class="btn btn-secondary my-1 mt-2 col-md-2 mr-0"
          >
            Cancelar
          </button>
        </div>
      </div>
    </form>

    <div *ngIf="listaAnexo.length > 0">
      <h5 class="border-top pt-3 mt-2">Lista de Anexos</h5>
      <ng-container *ngFor="let anexos of listaAnexo; let i = index">
        <div
          class="p-2 hoverable border rounded-lg"
          [ngClass]="i + 1 != listaAnexo.length ? 'mb-2' : ''"
          *ngFor="let anexo of anexos.Arquivos; let j = index"
        >
          <div *ngIf="anexo.ArquivoBase64">
            <div class="d-flex align-items-center">
              <img
                [src]="
                  anexo.Extensao == 'jpeg'
                    ? '../../../assets/img/imgIcon.png'
                    : '../../../assets/img/pdfIcon.png'
                "
                height="55"
                width="55"
                alt=""
              />
              <div class="col-5">
                <p class="font-weight-semibold mb-0">
                  {{ buscarNomeArquivo(anexo) }}
                </p>
                <p *ngIf="anexos.ArquivoSalvo" style="font-size: 10px">
                  {{ anexos.Arquivos[0].DataCadastro.ToDate }}
                </p>
                <p class="mb-0">{{ anexos.Comentario }}</p>
              </div>

              <div
                class="d-flex justify-content-end ml-auto p-0"
                *ngIf="
                  localStorage.TipoUsuario == '5' || localStorage.TipoUsuario == '10'
                    ? true
                    : localStorage.TipoUsuario == '1'
                    ? permiteMudanca(anexos.Arquivos[0].DescricaoTRG)
                    : false
                "
              >
                <button
                  type="button"
                  class="btn bg-primary btn-sm m-1"
                  (click)="downloadAnexo(anexo)"
                  title="Download"
                >
                  <i class="fa fa-download" aria-hidden="true"></i>
                </button>
                <!-- <button
                  [class.d-none]="!podeEditar"
                  type="button"
                  class="btn bg-primary btn-sm m-1"
                  (click)="editarAnexo(anexos, i)"
                  title="Editar"
                >
                  <i class="fa fa-pencil"></i>
                </button> -->
                <button
                  [class.d-none]="!podeEditar"
                  type="button"
                  class="btn btn-danger btn-sm m-1"
                  (click)="excluirAnexo(anexos)"
                  title="Excluir"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div *ngIf="loading && listaTipoArquivo.length === 0">
      <div class="d-flex col-md-12 form-group p-4 justify-content-center">
        <i class="fa fa-spin fa-spinner fa-2x"></i>
      </div>
    </div>
  </div>
</ng-container>
