<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<!-- Page content -->
<div class="page-content">
  <!-- Main content -->
  <div class="content-wrapper">
    <!-- Content area -->
    <div class="content d-flex justify-content-center align-items-center">
      <!-- Login form -->
      <form
        class="login-form"
        #login="ngForm"
        [formGroup]="formLogin"
        (submit)="logar(login)"
        *ngIf="!validaCliente && !validaServico"
      >
        <div class="card mb-0 bg-dark text-ligth">
          <div class="card-body">
            <div class="text-center mb-3">
              <img
                src="assets\img\elogica-gente.png"
                class="img-fluid max-width: 100% height-auto"
                style="max-height: 90px"
              /><br /><br />
              <h5 class="mb-0">Faça seu Login</h5>
              <span class="d-block text-muted">Entre com seu código do usuário ou CPF</span>
            </div>

            <div class="form-group form-group-feedback form-group-feedback-left">
              <input
                type="text"
                class="form-control"
                placeholder="Login"
                name="login"
                formControlName="login"
                [mask]="LoginMask()"
                required
              />

              <div class="form-control-feedback">
                <i class="icon-user text-muted"></i>
              </div>
              <span
                class="form-text text-danger"
                *ngIf="
                  formLogin.controls['login'].touched &&
                  formLogin.controls['login'].hasError('required')
                "
                >Digite o Login</span
              >
            </div>

            <div class="form-group form-group-feedback form-group-feedback-left">
              <input
                type="password"
                class="form-control"
                placeholder="Senha"
                name="Senha"
                formControlName="senha"
                required
                minlength="8"
              />
              <div class="form-control-feedback">
                <i class="icon-lock2 text-muted"></i>
              </div>
              <span
                class="form-text text-danger"
                *ngIf="
                  formLogin.controls['senha'].touched &&
                  formLogin.controls['senha'].hasError('required')
                "
                >Digite a Senha</span
              >
              <span
                class="form-text text-danger"
                *ngIf="
                  formLogin.controls['senha'].touched &&
                  formLogin.controls['senha'].hasError('minlength')
                "
                >Digite no mínimo 8 dígitos</span
              >
            </div>

            <div class="form-group form-group-feedback form-group-feedback-left">
              <button
                type="submit"
                class="btn btn-primary btn-block"
                [disabled]="formLogin.invalid || loading"
              >
                {{ loading == false ? 'Entrar' : 'Carregando...' }}
              </button>
              <small class="align-middle" style="cursor: pointer" (click)="recuperarSenha()"
                ><u>Esqueci minha senha</u>
              </small>
            </div>
          </div>
        </div>
      </form>

      <!-- /login form -->
    </div>
    <!-- /content area -->
    <div class="content d-flex justify-content-center align-items-center">
      <form #login="ngForm" [formGroup]="formLoginComplementar" class="login-form">
        <div class="card bg-dark text-ligth" *ngIf="validaCliente">
          <div mx-4 class="card-body">
            <div class="text-center mb-5">
              <h5 class="mb-0">Selecione o Cliente</h5>
            </div>

            <div class="form-group form-group-feedback form-group-feedback-left">
              <select
                class="form-control"
                formControlName="cliente"
                (change)="selecionarClienteServico(true)"
                required
              >
                <option value="">Selecione ...</option>
                <option *ngFor="let cli of info.Usuario.Clientes" value="{{ cli.IdCliente }}">
                  {{ cli.Nome }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div mx-4 class="card mb-0 bg-dark text-ligth" *ngIf="validaServico">
          <div class="card-body">
            <div class="text-center mb-5">
              <h5 class="mb-0">Selecione o Serviço</h5>
            </div>

            <div class="form-group form-group-feedback form-group-feedback-left">
              <select
                class="form-control"
                (change)="selecionarClienteServico(false)"
                formControlName="servico"
                required
              >
                <option value="">Selecione ...</option>
                <option *ngFor="let cli of info.Usuario.Servicos" value="{{ cli.Id }}">
                  {{ cli.Descricao }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
