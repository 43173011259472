<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Solicitando...</p>
</ngx-spinner>
<div [class]="telaDependente ? 'row mt-2' : 'mt-2'">
  <div class="col-xl-8" *ngIf="telaDependente">
    <div class="row">
      <div class="col-12">
        <div class="card shadow-sm">
          <div *ngIf="cadastroUsuario.DadosBancarios.Banco">
            <div class="card-header pb-0">
              <h3 class="text-center d-sm-none">Perfil do Colaborador</h3>
              <div class="d-flex flex-row mb-2 justify-content-sm-end justify-content-center">
                <h3 class="mr-sm-auto mb-0 text-center d-none d-sm-block">
                  <a
                    class="fa fa-arrow-left mr-2 pe-auto text-dark btn"
                    aria-hidden="true"
                    routerLink="/colaboradores"
                  ></a>
                  Perfil do Colaborador
                </h3>
                <button
                  *ngIf="
                    tipoUsuario !== '1' &&
                    cadastroUsuario.Stiuacao.Codigo == 98 &&
                    listaPendencias &&
                    !ColaboradorInativo
                  "
                  class="btn btn-sm bg-info mr-2"
                  title="Efetivar colaborador"
                  (click)="ativarCadastro()"
                >
                  <i class="fa fa-handshake-o pr-1" aria-hidden="true"></i>
                  Efetivar
                </button>
                <button
                  *ngIf="tipoUsuario !== '1' && !existeCredencial && !ColaboradorInativo"
                  (click)="enviarNovoEmail = null; emailCredencial = ''"
                  class="btn btn-sm bg-teal-400"
                  data-toggle="modal"
                  data-target="#criarCredicialModal"
                  data-trigger="hover"
                  data-placement="right"
                  title="Criar credencial"
                >
                  <i style="cursor: pointer" class="fa fa-user pr-1" aria-hidden="true"></i>
                  Criar credencial
                </button>
                <button
                  *ngIf="tipoUsuario !== '1' && existeCredencial && !ColaboradorInativo"
                  class="btn btn-sm bg-indigo-400"
                  data-target="#senha-email-modal"
                  data-toggle="modal"
                  data-trigger="hover"
                  data-placement="right"
                  title="Gerar uma nova senha"
                >
                  <i class="fa fa-lock pr-1" aria-hidden="true"></i>
                  Nova senha
                </button>
              </div>

              <!-- <div class="card-header">
                  <h3 id="target">Perfil</h3>
                </div> -->

              <div
                class="col-12 d-flex flex-column justify-content-between mt-2 align-items-center flex-md-wrap"
              >
                <img
                  *ngIf="
                    cadastroUsuario.Pessoal.DadosBasicos.Sexo &&
                    cadastroUsuario.Pessoal.DadosBasicos.Sexo.Valor != 0
                  "
                  [src]="
                    cadastroUsuario.Pessoal.DadosBasicos.Sexo.Valor == 1
                      ? 'assets/img/avatar-masculino.svg'
                      : 'assets/img/avatar-feminino.svg'
                  "
                  width="90"
                />
                <h4 class="card-title text-capitalize py-2 flex-grow-1 text-center">
                  <span>{{ cadastroUsuario.InformacoesBasicas.NomeCompleto.toLowerCase() }}</span>
                </h4>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive shadow-sm">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="text-muted">Situação</th>
                      <th class="text-muted">Departamento</th>
                      <th
                        *ngIf="
                          cadastroUsuario.InformacoesBasicas.Responsavel.NomeCompleto != '' &&
                          cadastroUsuario.InformacoesBasicas.Responsavel.NomeCompleto != '0'
                        "
                        class="text-muted"
                      >
                        Gestor
                      </th>
                      <th class="text-muted">Cargo</th>
                      <th class="text-muted">Matrícula</th>
                      <th class="text-muted">Vínculo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ cadastroUsuario.Stiuacao.Descricao }}</td>
                      <td>{{ cadastroUsuario.InformacoesBasicas.Departamento.NomeCompleto }}</td>
                      <td
                        *ngIf="
                          cadastroUsuario.InformacoesBasicas.Responsavel.NomeCompleto != '' &&
                          cadastroUsuario.InformacoesBasicas.Responsavel.NomeCompleto != '0'
                        "
                      >
                        {{ cadastroUsuario.InformacoesBasicas.Responsavel.NomeCompleto }}
                      </td>
                      <td>{{ cadastroUsuario.InformacoesBasicas.Cargo.Nome }}</td>
                      <td>{{ cadastroUsuario.Matricula }}</td>
                      <td>
                        {{ cadastroUsuario.InformacoesBasicas.Vinculo.Codigo }} -
                        {{ cadastroUsuario.InformacoesBasicas.Vinculo.Nome }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div
            *ngIf="!cadastroUsuario.DadosBancarios.Banco"
            class="d-flex justify-content-center align-items-center p-3"
          >
            <i class="fa fa-refresh fa-spin"></i>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card shadow-sm">
          <div class="card-body">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  (click)="clickMudancaTabs()"
                  data-toggle="tab"
                  href="#dados-pessoais"
                >
                  <i class="fa fa-user"></i>&nbsp;
                  <span>Dados Pessoais</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  (click)="clickMudancaTabs()"
                  data-toggle="tab"
                  href="#documentos"
                >
                  <i class="fa fa-file"></i>&nbsp;
                  <span>Documentos</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" (click)="clickMudancaTabs()" data-toggle="tab" href="#endereco">
                  <i class="fa fa-map"></i>&nbsp;
                  <span>Endereço</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" (click)="clickMudancaTabs()" data-toggle="tab" href="#anexos">
                  <i class="fa fa-file-arrow-up"></i>&nbsp;
                  <span>Anexos</span>
                </a>
              </li>
              <!-- <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#complemento">
                      <i class="fa fa-map"></i>&nbsp;
                      <span>Complemento cadastral</span>
                  </a>
              </li> -->
              <li
                *ngIf="
                  cadastroUsuario.Stiuacao.Codigo !== 97 && cadastroUsuario.Stiuacao.Codigo !== 98
                "
                class="nav-item"
              >
                <a
                  class="nav-link"
                  (click)="clickMudancaTabs()"
                  data-toggle="tab"
                  href="#relatorios"
                >
                  <i class="fa fa-download"></i>&nbsp;
                  <span>Relatórios</span>
                </a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane fade show active" id="dados-pessoais">
                <app-dados-pessoais
                  (attCadastro)="attCadastro($event)"
                  [ColaboradorInativo]="ColaboradorInativo"
                  [cadastroUsuario]="cadastroUsuario"
                  [uf]="uf"
                  [mudancaTab]="mudancaTab"
                ></app-dados-pessoais>
              </div>
              <div class="tab-pane fade show" id="documentos">
                <app-documentos-perfil
                  [ColaboradorInativo]="ColaboradorInativo"
                  *ngIf="cadastroUsuario.DadosBancarios.Banco"
                  [cadastroUsuario]="cadastroUsuario"
                  [mudancaTab]="mudancaTab"
                  [existeCredencial]="existeCredencial"
                ></app-documentos-perfil>
              </div>
              <div class="tab-pane fade show" id="endereco">
                <app-endereco
                  [ColaboradorInativo]="ColaboradorInativo"
                  [cadastroUsuario]="cadastroUsuario"
                  [mudancaTab]="mudancaTab"
                ></app-endereco>
              </div>
              <div class="tab-pane fade show" id="anexos">
                <app-anexo-perfil
                  [ColaboradorInativo]="ColaboradorInativo"
                  *ngIf="cadastroUsuario.DadosBancarios.Banco"
                  [cadastroUsuario]="cadastroUsuario"
                  [mudancaTab]="mudancaTab"
                  [existeCredencial]="existeCredencial"
                  (listaPendencias)="montaListaPendencias($event)"
                ></app-anexo-perfil>
              </div>
              <div class="tab-pane fade show" id="relatorios">
                <app-relatorios-perfil
                  [ColaboradorInativo]="ColaboradorInativo"
                  *ngIf="cadastroUsuario.DadosBancarios.Banco"
                  [cadastroUsuario]="cadastroUsuario"
                ></app-relatorios-perfil>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div [class]="telaDependente ? 'col-xl-4' : ''">
    <div [class]="telaDependente ? 'row' : ''">
      <div class="col-12" *ngIf="telaDependente">
        <app-salario
          [ColaboradorInativo]="ColaboradorInativo"
          [numeroOrdem]="cadastroUsuario.NumeroDeOrdem"
          [dadosBancario]="cadastroUsuario.DadosBancarios"
          [bancos]="bancos"
          [bancoUsuario]="bancoUsuario"
        ></app-salario>
      </div>
      <div class="col-12" *ngIf="telaDependente">
        <div class="card shadow-sm">
          <app-ferias-perfil
            *ngIf="cadastroUsuario.DadosBancarios.Banco"
            [ColaboradorInativo]="ColaboradorInativo"
            [cadastroUsuario]="cadastroUsuario"
          ></app-ferias-perfil>

          <div *ngIf="!cadastroUsuario.DadosBancarios.Banco">
            <div class="card-header">
              <h5 class="card-title text-uppercase text-muted">
                <i class="fa fa-money"></i>&nbsp;
                <span>Férias</span>
              </h5>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-center align-items-center">
                <i class="fa fa-refresh fa-spin fa"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [class]="telaDependente ? 'col-12' : ''">
        <!-- <app-dependentes-perfil
          (mudandoTela)="emissao($event)"
          (insereDep)="buscarDependentes($event)"
          [cadastroUsuario]="cadastroUsuario"
          [listaDependente]="listaDependente"
          [ColaboradorInativo]="ColaboradorInativo"
          [dadosUsuario]="dadosUsuario"
          ></app-dependentes-perfil> -->
        <app-dependentes
          [ColaboradorInativo]="ColaboradorInativo"
          [telaCadastro]="false"
          (mudandoTela)="emissao($event)"
          [matricula]="matricula"
          [listaDependente]="listaDependente"
          [cadastroUsuario]="cadastroUsuario"
        ></app-dependentes>
      </div>
    </div>
  </div>
  <scroll-top></scroll-top>
</div>

<!-- <div class="modal" id="senha-email-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div *ngIf="mudarSenha == 0" class="modal-header">
            <h5 class="modal-title">Nova Senha</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <label>Deseja Enviar para um email novo?</label>
            <div class="row" >
            <div class="col-md-12">
                <form #mudaSenha="ngForm" [formGroup]="formMudaSenha" novalidade  action=""  autocomplete="off" class="form-group">

                <!-- <div *ngIf="mudarSenha == 2">
                    <label  class="justify-content-start">Novo email</label>
                    <div  class="form-group form-group-feedback form-group-feedback-right row">
                    <div class="col-lg-12">
                        <div class="position-relative">
                        <div class="input-group">
                            <input type="email" name="email" formControlName="email" required class="form-control" >
                        </div>
                        </div>

                    </div>
                    </div>
                    </div>
                <div *ngIf="mudarSenha != 0" >
                <label  class="justify-content-start">Senha</label>
                <div class="form-group form-group-feedback form-group-feedback-right row">
                    <div class="col-lg-12">
                    <div class="position-relative">
                        <div class="input-group">
                        <input [type]="showInput ? 'text' : 'password'" name="senhaPerfil" formControlName="senhaPerfil" mask="00000000" required class="form-control" >
                        </div>
                        <div class="form-control-feedback">
                        <i *ngIf="!showInput" (click)="showInput = showInput == true ? false : true;" class="fa fa-eye fa-2x mt-1 mx-1" aria-hidden="true"></i>
                        <i *ngIf="showInput" (click)="showInput = showInput == true ? false : true;" class="fa fa-eye-slash fa-2x mt-1 mx-1" aria-hidden="true"></i>
                        </div>
                    </div>

                    </div>
                </div>

                </div>
                </form>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <button *ngIf="mudarSenha == 0" type="button" (click)="mudarSenha = 2" class="btn btn-success">Sim</button>
            <button *ngIf="mudarSenha == 0" type="button" (click)="mudarSenha = 1"  class="btn btn-default">Não</button>
            <button *ngIf="mudarSenha != 0" type="button"  (click)="enviarSenhaEmail()" class="btn btn-primary">Enviar</button>
        </div>
        </div>
    </div>
    </div> -->

<div
  class="modal fade"
  id="senha-email-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5
          *ngIf="
            (this.cadastroUsuario.Pessoal.Contato.Email1 !== '' ||
              this.cadastroUsuario.Pessoal.Contato.Email2 !== '') &&
            enviarNovoEmail == null
          "
          class="modal-title"
        >
          Você deseja enviar a credencial para o e-mail cadastrado?
        </h5>
        <h5
          *ngIf="
            (this.cadastroUsuario.Pessoal.Contato.Email1 === '' &&
              this.cadastroUsuario.Pessoal.Contato.Email2 === '') ||
            enviarNovoEmail
          "
          class="modal-title"
        >
          Informe o e-mail
        </h5>
        <button type="button" class="close" id="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #mudaSenha="ngForm"
          [formGroup]="formMudaSenha"
          novalidade
          action=""
          autocomplete="off"
          class="form-group"
        >
          <div
            *ngIf="
              (this.cadastroUsuario.Pessoal.Contato.Email1 === '' &&
                this.cadastroUsuario.Pessoal.Contato.Email2 === '') ||
              enviarNovoEmail
            "
            class="form-group"
          >
            <input
              type="email"
              [ngStyle]="
                formMudaSenha.controls['email'].status == 'INVALID' && temErroSenha
                  ? { 'border-color': 'red' }
                  : null
              "
              placeholder="Email"
              name="email"
              formControlName="email"
              required
              class="form-control"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formMudaSenha.controls['email'].dirty &&
                formMudaSenha.controls['email'].hasError('required') &&
                !temErroSenha
              "
              >Digite o E-mail</span
            >
            <span
              class="form-text text-danger"
              *ngIf="formMudaSenha.controls['email'].status == 'INVALID' && temErroSenha"
              >Digite o E-mail</span
            >
            <span
              class="form-text text-danger"
              *ngIf="formMudaSenha.controls['email'].hasError('maxlength')"
              >Digite o E-mail com 50 dígitos no máximo</span
            >
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div
          class="col-12 text-right"
          *ngIf="
            (this.cadastroUsuario.Pessoal.Contato.Email1 !== '' ||
              this.cadastroUsuario.Pessoal.Contato.Email2 !== '') &&
            enviarNovoEmail == null
          "
        >
          <button
            type="button"
            (click)="enviarNovoEmail = true"
            class="btn btn-secondary col-5 ml-2"
          >
            Novo e-mail
          </button>
          <button
            type="button"
            (click)="enviarSenha()"
            data-dismiss="modal"
            class="btn btn-primary col-5 ml-2"
          >
            Sim
          </button>
        </div>
        <div
          class="col-12 text-right"
          *ngIf="
            (this.cadastroUsuario.Pessoal.Contato.Email1 === '' &&
              this.cadastroUsuario.Pessoal.Contato.Email2 === '') ||
            enviarNovoEmail
          "
        >
          <button
            type="button"
            *ngIf="
              this.cadastroUsuario.Pessoal.Contato.Email1 !== '' &&
              this.cadastroUsuario.Pessoal.Contato.Email2 !== ''
            "
            (click)="enviarNovoEmail = null"
            class="btn btn-secondary col-2 ml-2"
          >
            Cancelar
          </button>
          <button
            type="button"
            (click)="enviarSenha()"
            [disabled]="formMudaSenha.get('email').value == ''"
            class="btn btn-primary col-2 ml-2"
          >
            Enviar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--
<div class="modal-dialog" role="document">
<div class="modal-content">
<div class="modal-header">
    <h3 class="modal-title" id="exampleModalLabel">Gerar Senha</h3>
    <button type="button" id="close" class="close" (click)="closeModalSenha()"  aria-label="Close">
    <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h5 *ngIf="!digitarEmail"> Deseja digitar um email para enviar Senha?</h5>
    <form #mudaSenha="ngForm" [formGroup]="formMudaSenha" novalidade  action=""  autocomplete="off" class="form-group">
    <div _ngcontent-uxj-c4="" class="col-md-12">
        <div _ngcontent-uxj-c4="" class="form-group">
        <div *ngIf="digitarEmail == true">
            <label  class="justify-content-start font-weight-semibold">Email</label>
            <input type="email" [ngStyle]="(formMudaSenha.controls['email'].status == 'INVALID' && temErroSenha) ? {'border-color':'red'} : ''" name="email" formControlName="email" required class="form-control" >
            <span class="form-text text-danger" *ngIf="formMudaSenha.controls['email'].dirty && formMudaSenha.controls['email'].hasError('required') && !temErroSenha">Digite o Email</span>
            <span class="form-text text-danger" *ngIf="formMudaSenha.controls['email'].status == 'INVALID' && temErroSenha ">Digite o Email</span>
            <span class="form-text text-danger" *ngIf="formMudaSenha.controls['email'].hasError('maxlength')">Digite o Email com 50 dígitos no máximo</span>
        </div>
        </div>
    </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" (click)="closeModalSenha()" class="btn btn-danger">Cancelar</button>
    <button type="button" *ngIf="!digitarEmail" (click)="digitarEmail = digitarEmail == true ? false: true" class="btn btn-primary">Sim</button>
    <button type="button" *ngIf="!digitarEmail" (click)="enviarSenha()" data-dismiss="modal" class="btn btn-secondary">Não</button>
    <button type="button" (click)="enviarSenha()" *ngIf="digitarEmail" class="btn btn-primary">Enviar Senha</button>
</div>
</div>
</div> -->

<!-- modal abrir imagem-->
<div
  class="modal fade"
  id="imageModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="imageModal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img [src]="docImage" class="card-img-top" />
      </div>
    </div>
  </div>
</div>
<!-- /modal abrir imagem-->
<!-- modal para criar credenciais -->
<div class="modal" id="criarCredicialModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5
          *ngIf="
            (this.cadastroUsuario.Pessoal.Contato.Email1 !== '' ||
              this.cadastroUsuario.Pessoal.Contato.Email2 !== '') &&
            enviarNovoEmail == null
          "
          class="modal-title"
        >
          Você deseja enviar a credencial para o e-mail cadastrado?
        </h5>
        <h5
          *ngIf="
            (this.cadastroUsuario.Pessoal.Contato.Email1 === '' &&
              this.cadastroUsuario.Pessoal.Contato.Email2 === '') ||
            enviarNovoEmail
          "
          class="modal-title"
        >
          Informe o e-mail
        </h5>
        <button
          type="button"
          class="close"
          id="fecharModalCredencial"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div
          *ngIf="
            (this.cadastroUsuario.Pessoal.Contato.Email1 === '' &&
              this.cadastroUsuario.Pessoal.Contato.Email2 === '') ||
            enviarNovoEmail
          "
          class="form-group"
        >
          <input
            type="text"
            placeholder="Email"
            [(ngModel)]="emailCredencial"
            class="form-control"
          />
        </div>
      </div>
      <div class="modal-footer">
        <div
          class="col-12 text-right"
          *ngIf="
            (this.cadastroUsuario.Pessoal.Contato.Email1 !== '' ||
              this.cadastroUsuario.Pessoal.Contato.Email2 !== '') &&
            enviarNovoEmail == null
          "
        >
          <button type="button" (click)="enviarNovoEmail = true" class="btn btn-secondary col-5">
            Novo e-mail
          </button>
          <button type="button" (click)="criarCredenciais()" class="btn btn-primary col-5 ml-2">
            Sim
          </button>
        </div>
        <div
          class="col-12 text-right"
          *ngIf="
            (this.cadastroUsuario.Pessoal.Contato.Email1 === '' &&
              this.cadastroUsuario.Pessoal.Contato.Email2 === '') ||
            enviarNovoEmail
          "
        >
          <button
            type="button"
            *ngIf="
              this.cadastroUsuario.Pessoal.Contato.Email1 !== '' &&
              this.cadastroUsuario.Pessoal.Contato.Email2 !== ''
            "
            (click)="enviarNovoEmail = null"
            class="btn btn-secondary col-2"
          >
            Cancelar
          </button>
          <button
            type="button"
            [disabled]="emailCredencial == ''"
            (click)="criarCredenciais(emailCredencial)"
            class="btn btn-primary ml-2 col-5"
          >
            Enviar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
