import { Component, OnInit, Input } from '@angular/core';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { ProfilePDF } from './../../profile-PDF';
import { RelatoriosPerfilService } from './relatorios-perfil.service';
import { Router, NavigationExtras } from '@angular/router';
import { ErroService } from 'src/app/service/erros/erro.service';
import pdfMake from 'pdfmake/build/pdfmake';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert';
import { dataAtualOuAnteriorValidator } from 'src/app/service/Validators/dataMenorIgualValidator';
import { AlertaModal } from 'src/app/componentes/componentes-funcionais/alerta-modal/alerta-modal.component';
@Component({
  selector: 'app-relatorios-perfil',
  templateUrl: './relatorios-perfil.component.html',
  styleUrls: ['./relatorios-perfil.component.css'],
})
export class RelatoriosPerfilComponent implements OnInit {
  @Input() cadastroUsuario: Cadastro;
  @Input() ColaboradorInativo: boolean;

  form: FormGroup;
  pdfteste: any;
  loadingDadosPagamentoBt: boolean;
  loadingFichaFinanceiraBt: boolean = false;
  loadingDadosPessoaisBt: boolean;
  cardItems = [];

  constructor(
    private relatorioService: RelatoriosPerfilService,
    private route: Router,
    private validaErro: ErroService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      modoImpressao: ['', Validators.required],
      dtInicio: ['', [Validators.required]],
      dtFim: ['', [Validators.required]],
      modeloImpressao: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.cardItems = [
      {
        icon: 'fa-user',
        label: 'Dados Pessoais',
        clickFunction: () => this.gerarPdfDadosPessoais(this.cadastroUsuario),
        loadingProperty: 'loadingDadosPessoaisBt',
        disabledProperty: this.ColaboradorInativo, // download desabilitado enquanto pdf não é gerado/resolvido
      },
      {
        icon: 'fa-credit-card',
        label: 'Dados de Pagamento',
        clickFunction: () => this.gerarPdfDadosPagamento(),
        loadingProperty: 'loadingDadosPagamentoBt',
        disabledProperty: this.ColaboradorInativo, // download desabilitado enquanto pdf não é gerado/resolvido
      },
      {
        icon: 'fa-file-text',
        label: 'Contracheque',
        clickFunction: () => this.navegarPara('contracheque'),
        loadingProperty: '', // Não há propriedade de loading neste exemplo
        disabledProperty: this.ColaboradorInativo,
      },
      {
        icon: 'fa-money',
        label: 'Ficha Financeira',
        clickFunction: () => this.modalFichaFinaceira(event),
        loadingProperty: '',
        disabledProperty: this.ColaboradorInativo, // download desabilitado enquanto pdf não é gerado/resolvido
      },
      // {
      //   icon: 'fa-sticky-note',
      //   label: 'Processos',
      //   clickFunction: () => this.navegarPara('processos'),
      //   loadingProperty: '',
      //   disabledProperty: this.ColaboradorInativo,
      // },
    ];
  }

  gerarPdfDadosPessoais(cadastro: Cadastro): void {
    this.loadingDadosPessoaisBt = true;
    const profilePdf: ProfilePDF = new ProfilePDF();
    const pdf = profilePdf.gerarPDF(cadastro);
    pdf.then((data) =>
      pdfMake
        .createPdf(data)
        .download(
          'RELATORIO_DADOS_COLABORADOR-' +
            cadastro.InformacoesBasicas.NomeCompleto.replace(/\s+/g, '_'),
          () => {
            this.loadingDadosPessoaisBt = false;
          }
        )
    );
  }

  modalFichaFinaceira(evento) {
    this.limparFormFichaFinanceira();
    this.form.get('modoImpressao').setValue('1');
    this.form.get('dtFim').disable();
    this.form.get('modeloImpressao').setValue('2');
    const elemento = evento.target;
    elemento.setAttribute('data-toggle', 'modal');
    elemento.setAttribute('data-target', '#ficha');
  }

  limparFormFichaFinanceira() {
    this.form.setValue({
      modoImpressao: '',
      dtInicio: '',
      dtFim: '',
      modeloImpressao: '',
    });
    this.form.markAsUntouched();
  }

  imprimirFichaFinanceira() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    } else {
      this.gerarPDFFichaFinanceira();
    }
  }

  montarFichaFinanceira(): formFichaFinanceira {
    const form = this.form.getRawValue();
    const dtFim =
      form.modoImpressao === '1' ? form.dtFim + '12' : this.formatarDataCompObjeto(form.dtFim);
    return {
      modoImpressao: form.modoImpressao === '1' || form.modoImpressao === '3' ? '1' : '2',
      dtInicio: this.formatarDataCompObjeto(form.dtInicio),

      dtFim: dtFim,
      modeloImpressao: form.modeloImpressao,
    };
  }

  gerarPDFFichaFinanceira() {
    const objetoFichaFinanceira = this.montarFichaFinanceira();

    this.loadingFichaFinanceiraBt = true;
    this.relatorioService
      .buscarFichaFinanceira(this.cadastroUsuario, objetoFichaFinanceira)
      .subscribe({
        next: (data: any) => {
          const linkSource = 'data:application/pdf;base64,' + data.Relatorio;
          const downloadLink = document.createElement('a');
          const fileName = 'dados-pagamento.pdf';

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          this.loadingFichaFinanceiraBt = false;
        },
        error: () => {
          this.loadingFichaFinanceiraBt = false;
          new AlertaModal(
            'erro',
            'Erro',
            'Não foi possivel obter informações do colaborador para gerar o relatório.',
            'p'
          );
        },
      });
  }

  gerarPdfDadosPagamento() {
    this.loadingDadosPagamentoBt = true;
    this.relatorioService.buscarDadosPagamento(this.cadastroUsuario).subscribe(
      (data: any) => {
        const linkSource = 'data:application/pdf;base64,' + data.Relatorio;
        const downloadLink = document.createElement('a');
        const fileName = 'dados-pagamento.pdf';

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.loadingDadosPagamentoBt = false;
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.loadingDadosPagamentoBt = false;
      }
    );
  }

  mudandoModoImpressao() {
    this.form.get('dtInicio').setValue('');
    this.form.get('dtFim').setValue('');
    this.form.get('dtInicio').markAsUntouched();
    this.form.get('dtFim').markAsUntouched();

    const modoImpressao = this.form.get('modoImpressao').value;
    if (modoImpressao !== '3') {
      this.form.get('dtFim').disable();
    } else {
      this.form.get('dtFim').enable();
    }
  }

  navegarPara(pagina) {
    const navigationExtras: NavigationExtras = {
      state: {
        usuario: {
          cpf: this.cadastroUsuario.Documentos.Cpf,
          nome: this.cadastroUsuario.InformacoesBasicas.NomeCompleto,
          matricula: this.cadastroUsuario.Matricula,
          numOrdem: this.cadastroUsuario.NumeroDeOrdem,
        },
      },
    };
    this.route.navigate([pagina], navigationExtras);
  }

  definirObrigatoriedadeDataFim() {
    const modoImpressao = this.form.get('modoImpressao').value;
    if (modoImpressao === '3') {
      this.form.get('dtFim').setValidators(Validators.required);
    } else {
      this.form.get('dtFim').clearValidators();
      this.form.get('dtFim').markAsUntouched();
    }
    this.form.get('dtFim').updateValueAndValidity();
  }

  formatarDataCompObjeto(dataInput: string): string {
    console.log('datainput', dataInput);
    if (dataInput.length == 6) {
      const mesComp = dataInput.substring(0, 2);
      const anoComp = dataInput.substring(2, 7);

      return anoComp + mesComp;
    } else {
      return dataInput + '01';
    }
  }

  definirDataFinal12Meses(evento: any) {
    const data = evento.target.value;
    let mes = data.slice(0, 2);
    let ano = data.slice(3, 7);
    if (data.length > 6) {
      let novoMes = Number(mes) + 11;
      let novoAno = Number(ano);

      if (novoMes > 12) {
        novoMes -= 12;
        novoAno += 1;
      }

      const dtFim = novoMes.toString().padStart(2, '0') + novoAno.toString().padStart(2, '0');
      this.form.get('dtFim').setValue(dtFim);
    } else {
      this.form.get('dtFim').setValue('');
    }
  }

  formatarDataCompInput(evento: any) {
    const data = evento.target.value;
    const mes = parseInt(data.slice(0, 2), 10);
    const ano = parseInt(data.slice(3, 7), 10);
    const dataAtual = new Date();
    const mesAtual = dataAtual.getMonth() + 1;
    const anoAtual = dataAtual.getFullYear();

    if (ano > anoAtual || (ano === anoAtual && mes > mesAtual)) {
      const mesFormatado = mesAtual.toString().padStart(2, '0');
      evento.target.value = `${mesFormatado}/${anoAtual}`;
    } else {
      if (mes > 12) {
        evento.target.value = `12/${ano}`;
      }
    }
  }

  formatarAnoInput(evento: any, formcontrol: string) {
    const ano = evento.target.value;

    let anoAtual = new Date().getFullYear();
    if (ano > anoAtual) {
      evento.target.value = anoAtual;
      this.form.get(formcontrol).setValue(anoAtual);
    }
  }

  definirAnoFinal(evento: any, formcontrol: string) {
    const ano = evento.target.value;
    const modoImpressao = this.form.get('modoImpressao').value;

    if (formcontrol === 'dtInicio' && modoImpressao === '1') {
      if (ano.length === 4) {
        this.form.get('dtFim').setValue(ano);
      } else {
        this.form.get('dtFim').setValue('');
      }
    }
  }
}

export interface formFichaFinanceira {
  modoImpressao: string;
  dtInicio: string;
  dtFim: string;
  modeloImpressao: string;
}
