<app-top-menu *ngIf="auth.validaLogin()"></app-top-menu>
<ngx-spinner
  name="permissoes"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Verificando Permissões...</p>
</ngx-spinner>
<!-- Page content -->
<div class="page-content">
  <app-side-menu
    *ngIf="auth.validaLogin()"
    style="display: flex; margin-top: 300px; max-height: 200px !important"
  ></app-side-menu>

  <!-- Main content -->
  <div class="content-wrapper mt-md-5">
    <!-- Conteudo -->
    <div class="content container">
      <router-outlet></router-outlet>
    </div>
    <!-- <div class="text-center"><i class="fa fa-spinner fa-spin fa-5x" aria-hidden="false"></i></div> -->
    <!-- /Conteudo *ngIf="loading == 'true'" -->
    <br /><br /><br /><br />
    <!-- Footer -->
    <div class="navbar navbar-expand-lg navbar-light fixed-bottom" *ngIf="validaLogin">
      <div class="text-center d-lg-none w-100">
        <button
          type="button"
          class="navbar-toggler dropdown-toggle"
          data-toggle="collapse"
          data-target="#navbar-footer"
        >
          <i class="icon-unfold mr-2"></i>
          Click para opções
        </button>
      </div>

      <div class="navbar-collapse collapse" id="navbar-footer">
        <!-- <span class="navbar-text overlay" >
					<b>&copy; Ayu!Gate</b>
				</span> -->
        <ul class="navbar-nav ml-lg-auto">
          <!-- <li class="nav-item"><a href="#" class="navbar-nav-link"><i class="icon-lifebuoy mr-2"></i> Suporte</a></li> -->
          <!-- <li class="nav-item mr-5"><a href="#" class="navbar-nav-link"><i class="icon-file-text2 mr-2"></i> Documentação</a></li> -->
          <span class="navbar-text overlay">
            <b>&copy; Elógica</b>
          </span>
        </ul>
      </div>
    </div>
    <!-- /footer -->
  </div>
  <!-- /main content -->
</div>
<!-- /page content -->
<!--habilita script de chat apenas em tela do sistema em que o usuario esteja logado-->
<script type="text/javascript">
  console.log('oi');
  console.log(auth.validaLogin());
  if (auth.validaLogin()) {
    var $zoho = $zoho || {};
    $zoho.salesiq = $zoho.salesiq || {
      widgetcode: 'b2a99ecc2a580a9bd1c14a4ed7f228135f894912c659ba35110fdc9af644f998',
      values: {},
      ready: function () {},
    };
    var d = document;
    s = d.createElement('script');
    s.type = 'text/javascript';
    s.id = 'zsiqscript';
    s.defer = true;
    s.src = 'https://salesiq.zoho.eu/widget';
    t = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
    d.write("<div id='zsiqwidget'></div>");
  }
</script>
