import {TopMenuService} from './top-menu.service';
import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {HomePageComponent} from '../home-page/home-page.component';
import {ErroService} from '../../service/erros/erro.service';
import Swal from 'sweetalert';
import {Location} from '@angular/common';
import {LocalStorage} from 'src/app/service/classes/localstorage/LocalStorage';
import {Crypto} from 'src/app/service/classes/crypto/crypto';
import {Aviso} from '../ficha-funcional/avisos/avisos.component';
import {AvisosService} from '../ficha-funcional/avisos/avisos.service';
import {AlertaModal} from '../componentes-funcionais/alerta-modal/alerta-modal.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css'],
  providers: [HomePageComponent],
})
export class TopMenuComponent implements OnInit {
  localStorage: LocalStorage = new LocalStorage();

  listaMensagem: Aviso[] = [];
  listaVisualizacao: Visualizacao[] = [];
  listaBooleanVisualizacao: boolean[] = [];

  rotaAtual: string;
  senhaAtual: string;
  novaSenha: string;
  confirmarSenha: string;
  //public valida_click:boolean = true;

  constructor(
    public router: Router,
    public auth: AuthService,
    public avisoService: AvisosService,
    public topMenuService: TopMenuService,
    public home: HomePageComponent,
    private validaErro: ErroService,
    private cdr: ChangeDetectorRef,
    private location: Location
  ) {}

  ngOnInit() {
    this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    this.rotaAtual = this.location.path();
    this.buscarVisualizacao();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  alterarFilial(codigo, nome) {
    Swal({
      title: 'Atenção',
      text: 'Tem certeza de que deseja mudar de filial?',
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancelar', 'Confirmar'],
      closeOnClickOutside: false,
      closeOnEsc: false,
    }).then((confirmacao) => {
      if (confirmacao) {
        // Aqui você pode colocar o código a ser executado se o usuário confirmar
        this.localStorage.FilialAtual = codigo;
        this.localStorage.NomeFilialAtual = nome;
        this.localStorage.FilialPrevidenciaria = this.localStorage.Filiais.find(
          (filial) => filial.Codigo === codigo
        ).EmpresaPrevidenciaria;
        localStorage.setItem(
          'util',
          new Crypto().encryptUsingAES256(JSON.stringify(this.localStorage))
        );

        Swal('Alteração confirmada!', 'Você mudou de filial com sucesso.', 'success').then(() => {
          window.location.reload();
        });
      } else {
        // Aqui você pode colocar o código a ser executado se o usuário cancelar
        Swal('Alteração cancelada!', 'Você permaneceu na mesma filial.', 'info');
      }
    });
  }

  buscarVisualizacao() {
    this.topMenuService.buscarVisualizacao(this.localStorage.Sessao).subscribe(
      (data: Visualizacao[]) => {
        this.listaVisualizacao = [];
        this.listaBooleanVisualizacao = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i].Usuario == this.localStorage.NomeUsuario.toUpperCase()) {
            this.listaBooleanVisualizacao.push(data[i].Visualizado);
            this.listaVisualizacao.push(data[i]);
          }
        }
        this.buscarMensagem();
      },
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarMensagem() {
    this.avisoService.buscarMensagem().subscribe((data: Aviso[]) => {
      let aux = 1;
      this.listaMensagem = data;
      if (this.listaVisualizacao.length == 0) {
        for (let i = 0; i < data.length; i++) {
          if (i >= this.listaVisualizacao.length) {
            this.salvarVisualizacao({
              CodMsn: data[i].Id,
              Usuario: this.localStorage.NomeUsuario,
              Visualizado: false,
              DataFinal: Number(data[i].DataFinal),
            });
          }
        }
      } else if (this.listaVisualizacao.length < this.listaMensagem.length) {
        try {
          for (let i = 0; i < data.length; i++) {
            aux++;
            if (aux > this.listaVisualizacao.length) {
              this.salvarVisualizacao({
                CodMsn: data[i + 1].Id,
                Usuario: this.localStorage.NomeUsuario,
                Visualizado: false,
                DataFinal: Number(data[i + 1].DataFinal),
              });
            }
          }
        } catch {}
      }
    });
  }

  salvarVisualizacao(visualizacao: Visualizacao) {
    this.topMenuService.salvarVisualizacao(visualizacao).subscribe(
      () => {},
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  editarVisualizacao(id: number, data: number) {
    this.topMenuService.editarVisualizacao({Visualizado: true}, id).subscribe(
      () => {},
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  obterMensagem(aviso: Aviso) {
    for (let i = 0; i < this.listaVisualizacao.length; i++) {
      if (this.listaVisualizacao[i].CodMsn == aviso.Id) {
        this.editarVisualizacao(this.listaVisualizacao[i].Id, Number(aviso.DataFinal));
      }
    }
    new AlertaModal('alerta', 'Atenção', 'Preencha o campo Data de interrupção', 'p');
    Swal(`Mensagem (${aviso.TipoAviso})`, aviso.Mensagem);
    this.buscarVisualizacao();
  }

  montarListaAoClicar() {
    this.buscarVisualizacao();
    setTimeout(() => {
      this.buscarMensagem();
    }, 1000);
  }

  contadorVizualizada(): number {
    let contador = 0;
    this.listaBooleanVisualizacao.forEach((value) => {
      if (value == false) {
        contador++;
      }
    });
    return contador;
  }

  montarVisualizado(): boolean {
    const validar = this.listaBooleanVisualizacao.some((value) => value == false);
    return validar;
  }

  sair() {
    this.auth.logout();
  }

  AlterarSenha() {
    const alterarSenha = {
      senhaAtual: this.senhaAtual,
      senhaNova: this.novaSenha,
    };
    try {
      if (this.novaSenha != this.confirmarSenha) {
        new AlertaModal('alerta', 'Confira as senhas digitadas!', 'As senhas não são iguais.', 'p');
        return false;
      }

      this.topMenuService.AlterarSenha(alterarSenha).subscribe(
        (data) => {
          new AlertaModal('sucesso', 'Sucesso!', 'Sua senha foi modificada.', 'p');

          const btnClose = document.getElementById('btn-close');
          btnClose.click();
        },
        (error) => {
          new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
        }
      );
    } catch (error) {}
  }

  retornarPerfil() {
    const rota = '/perfil/' + this.localStorage.NumeroOrdemUsuario;
    this.router.navigate([rota]);
  }

  limparCamposSenha() {
    this.senhaAtual = '';
    this.novaSenha = '';
    this.confirmarSenha = '';
  }
}

export class Visualizacao {
  Id?: number;
  CodMsn?: number;
  Usuario?: string;
  Visualizado: boolean;
  DataFinal?: number;
}
