<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div class="modal-header">
  <h3 class="modal-title">{{ cadastro ? 'Incluir' : 'Atualizar' }} Falta</h3>
  <button type="button" class="close" id="botaoFechar" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="formFaltas" class="modal-body row">
  <div class="form-group col-6">
    <label for="CodAfa" class="font-weigth-semibold">Afastamento</label>
    <select
      class="form-control"
      id="CodAfa"
      formControlName="CodAfa"
      [ngClass]="
        (formFaltas.get('CodAfa').invalid || formFaltas.get('CodAfa').value == null) &&
        formFaltas.get('CodAfa').touched
          ? 'is-invalid'
          : null
      "
    >
      <option value="">Selecione uma opção</option>
      <option *ngFor="let item of listaAfastamentos" [value]="item.Cod">
        {{ item.Cod }} - {{ item.Nome }}
      </option>
    </select>
    <span
      class="form-text text-danger"
      *ngIf="
        (formFaltas.get('CodAfa').invalid || formFaltas.get('CodAfa').value == null) &&
        formFaltas.get('CodAfa').touched
      "
    >
      Campo Obrigatório
    </span>
  </div>
  <div class="form-group col-6">
    <label for="CodMot" class="font-weigth-semibold">Motivo</label>
    <select
      class="form-control"
      id="CodMot"
      formControlName="CodMot"
      [ngClass]="
        (formFaltas.get('CodMot').invalid || formFaltas.get('CodMot').value == null) &&
        formFaltas.get('CodMot').touched
          ? 'is-invalid'
          : null
      "
    >
      <option value="">Selecione uma opção</option>
      <option *ngFor="let item of listaMotivos" [value]="item.Cod">
        {{ item.Cod }} - {{ item.Nome }}
      </option>
    </select>
    <span
      class="form-text text-danger"
      *ngIf="
        (formFaltas.get('CodMot').invalid || formFaltas.get('CodMot').value == null) &&
        formFaltas.get('CodMot').touched
      "
    >
      Campo Obrigatório
    </span>
  </div>

  <div class="form-group col-4">
    <label for="DtInicio" class="font-weigth-semibold">Data Início</label>
    <input
      type="date"
      max="9999-12-31"
      class="form-control"
      id="DtInicio"
      formControlName="DtInicio"
      [ngClass]="
        (formFaltas.get('DtInicio').invalid || formFaltas.get('DtInicio').value == null) &&
        formFaltas.get('DtInicio').touched
          ? 'is-invalid'
          : null
      "
    />
    <span
      class="form-text text-danger"
      *ngIf="
        (formFaltas.get('DtInicio').invalid || formFaltas.get('DtInicio').value == null) &&
        formFaltas.get('DtInicio').touched
      "
    >
      {{
        formFaltas.get('DtInicio').hasError('data')
          ? 'Data deve ser menor que data atual'
          : 'Campo Obrigatório'
      }}
    </span>
  </div>
  <div class="form-group col-4">
    <label for="DtVolta" class="font-weigth-semibold">Data Volta</label>
    <input
      type="date"
      max="9999-12-31"
      class="form-control"
      id="DtVolta"
      formControlName="DtVolta"
      [ngClass]="
        (formFaltas.get('DtVolta').invalid || formFaltas.get('DtVolta').value == null) &&
        formFaltas.get('DtVolta').touched
          ? 'is-invalid'
          : null
      "
    />
    <span
      class="form-text text-danger"
      *ngIf="
        (formFaltas.get('DtVolta').invalid || formFaltas.get('DtVolta').value == null) &&
        formFaltas.get('DtVolta').touched
      "
    >
      {{
        formFaltas.get('DtVolta').hasError('data')
          ? 'Data deve ser menor que data atual'
          : 'Campo Obrigatório'
      }}
    </span>
  </div>
  <div class="form-group col-4">
    <label for="NumeroProcesso" class="font-weigth-semibold"
      >Número do Processo <small>(opcional)</small></label
    >
    <input
      type="text"
      class="form-control"
      id="NumeroProcesso"
      formControlName="NumeroProcesso"
      style="text-transform: uppercase;"
      placeholder="000"
    />
  </div>

  <div class="form-group col-6">
    <label for="CodMed" class="font-weigth-semibold">CRM Médico <small>(opcional)</small></label>
    <input
      type="text"
      class="form-control"
      id="CodMed"
      formControlName="CodMed"
      placeholder="000000"
    />
  </div>
  <div class="form-group col-6">
    <label for="CodMedUF" class="font-weigth-semibold">CRM UF <small>(opcional)</small></label>
    <select class="form-control" id="CodMedUF" formControlName="CodMedUF">
      <option value="">Selecione uma opção</option>
      <option *ngFor="let uf of listaUF" value="{{ uf.Descricao }}">
        {{ uf.Descricao }}
      </option>
    </select>
  </div>
  <div class="form-group col-5 d-none">
    <label for="NomeMed" class="font-weigth-semibold">Nome Médico <small>(opcional)</small></label>
    <input
      type="text"
      class="form-control"
      id="NomeMed"
      formControlName="NomeMed"
      placeholder="CRM não selecionado"
    />
  </div>

  <div class="form-group col-3">
    <label for="CodDiag" class="font-weigth-semibold">CID <small>(opcional)</small></label>
    <input
      type="text"
      class="form-control"
      id="CodDiag"
      style="text-transform: uppercase;"
      formControlName="CodDiag"
      placeholder="A00"
      mask="S00"
      (input)="buscarCID()"
    />
    <span class="form-text text-danger" *ngIf="cidAtual == null && formFaltas.get('CodDiag').dirty">
      CID não encontrado
    </span>
  </div>
  <div class="form-group col-9">
    <label for="nomeCID" class="font-weigth-semibold"
      >Descrição CID <small>(opcional)</small></label
    >
    <input
      type="text"
      class="form-control"
      id="nomeCID"
      formControlName="nomeCID"
      placeholder="CID não selecionado"
    />
  </div>

  <div class="form-group col-8">
    <label for="NumeroDoe" class="font-weigth-semibold">Número DOE <small>(opcional)</small></label>
    <input
      type="text"
      class="form-control"
      id="NumeroDoe"
      style="text-transform: uppercase;"
      formControlName="NumeroDoe"
      placeholder="000"
    />
  </div>
  <div class="form-group col-4">
    <label for="DtDoe" class="font-weigth-semibold">Data DOE <small>(opcional)</small></label>
    <input
      type="date"
      max="9999-12-31"
      class="form-control"
      id="DtDoe"
      formControlName="DtDoe"
      [ngClass]="
        formFaltas.get('DtDoe').invalid && formFaltas.get('DtDoe').touched ? 'is-invalid' : null
      "
    />
    <span
      class="form-text text-danger"
      *ngIf="formFaltas.get('DtDoe').invalid && formFaltas.get('DtDoe').touched"
    >
      Data deve ser menor que data atual
    </span>
  </div>

  <div class="form-group col-5">
    <label for="IdClassificacaoAto" class="font-weigth-semibold"
      >Classificação Ato <small>(opcional)</small></label
    >
    <select class="form-control" id="IdClassificacaoAto" formControlName="IdClassificacaoAto">
      <option value="">Selecione uma opção</option>
      <option *ngFor="let item of listaClassificacaoAto" [value]="item.Cod">
        {{ item.Cod }} - {{ item.Nome }}
      </option>
    </select>
  </div>
  <div class="form-group col-3">
    <label for="NumeroAto" class="font-weigth-semibold">Número Ato <small>(opcional)</small></label>
    <input
      type="text"
      class="form-control"
      id="NumeroAto"
      style="text-transform: uppercase;"
      formControlName="NumeroAto"
      placeholder="000"
    />
  </div>
  <div class="form-group col-4">
    <label for="DtAto" class="font-weigth-semibold">Data Ato <small>(opcional)</small></label>
    <input
      type="date"
      max="9999-12-31"
      class="form-control"
      id="DtAto"
      formControlName="DtAto"
      [ngClass]="
        formFaltas.get('DtAto').invalid && formFaltas.get('DtAto').touched ? 'is-invalid' : null
      "
    />
    <span
      class="form-text text-danger"
      *ngIf="formFaltas.get('DtAto').invalid && formFaltas.get('DtAto').touched"
    >
      Data deve ser menor que data atual
    </span>
  </div>

  <div class="form-check ml-2">
    <input
      class="form-check-input"
      type="checkbox"
      value=""
      id="penalizarFalta"
      formControlName="penalizarFalta"
    />
    <label class="form-check-label" for="penalizarFalta">
      Penalizar falta <small>(opcional)</small>
    </label>
  </div>
</form>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="cadastro ? lancarFalta() : lancarFalta(faltaAtual)"
  >
    {{ cadastro ? 'Cadastrar' : 'Atualizar' }}
  </button>
</div>
