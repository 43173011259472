import { Anexo } from "../../anexo/anexo.component";
import { Data } from "src/app/service/interfaces/Data";

export enum Nacionalidade {
  Brasileiro = 10,
}

export class Cadastro {
  matricula: string = "0";
  numeroDeOrdem: string;
  IsCadastro: boolean;
  CelularesSecundarios: ContatosSecundarios[];
  dadosPessoais: DadosPessoais;
  dadosEstrangeiro: DadosEstrangeiro;
  Identidade: Identidade;
  CarteiraTrabalhista: CarteiraDeTrabalho;
  CarteiraDeHabilitacao: Cnh;
  Profissao: Profissao;
  TituloEleitor: TituloEleitor;
  Pis: Pis;
  CertificadoMilitar: CertificadoMilitar;
  estabilidade: string;
  tipoFrequencia: string;
  dadosBasicos: DadosBasicos;
  // emprego: Emprego;
  contato: Contato;
  endereco: Endereco;
  adts: Adts;
  nomeacao: Nomeacao;
  descontosCompulsorios: DescontosCompulsorios;
  contaBancaria: ContaBancaria;
  Flags: Flags;
  contratoTemporario?: ContratoTemporario;
  periodoExperiencia?: PeriodoExperiencia;
  bloquearConsignado: boolean;
  codigoDiasUteis: string;
  Anexos: Anexo[];
}

export interface Endereco {
  bairro: string;
  cep: string;
  cidade: string;
  complemento: string;
  logradouro: string;
  numero: number;
  uf: string;
  valido?: true;
}

export interface EnderecoViaCep extends Omit<Endereco, "valido"> {
  Valido: boolean;
}

export interface DadosEstrangeiro {
  dataChegada: Data;
  naturalizado: number;
  casadoComBrasileiro: number;
  filhoBrasileiro: number;
}

export interface Emprego {
  primeiroEmprego: boolean;
  outroEmprego: boolean;
  pagaContribuicao: number;
  cargaHoraria: number;
  dataExameMedico: Data;
}

export interface Ocupacao {
  vinculo: number;
  dataAdmissao: Data;
  cargo: string;
  nivel: string;
}

export interface Identidade {
  registroGeral: number;
  orgaoEmissor: string;
  dataDeExpedicao: Data;
  uF: string;
}

export interface CarteiraDeTrabalho {
  numero: string;
  serieCTPS: string;
  ufCTPS: string;
}

export interface Cnh {
  numero: string;
  categoriaCnh: string;
  serie: string;
  dataValidadeCnh: Data;
}

export interface Profissao {
  registro: string;
  orgaoExpedidor: string;
  uF: string;
}

export interface TituloEleitor {
  numero: string;
  zonaEleitoral: string;
  secaoEleitoral: string;
  ufEleitoral: string;
}

export interface Pis {
  numero: number;
  // dataDeEmissao: Data;
  // agencia: string;
}

export interface CertificadoMilitar {
  numero: string;
  csm: string;
  serie: string;
}

export interface EnderecoEmpresa {
  MunicipioDetrabalho: string;
  Setor: string;
}

export interface ContaBancaria {
  banco: string;
  agencia: string;
  operacao: string;
  numeroConta: string;
}

export interface JornadaDeTrabalho {
  horario: string;
  cargaHoraria: string;
  descanso: string;
}

export interface Contato {
  DDDTelefone: number;
  Telefone: string;
  DDDCelular: number;
  Celular: string;
  Email1: string;
  Email2: string;
}

export interface ContatosSecundarios {
  Numord: string;
  DDDSecundario: string;
  CelularSecundario: string;
  TipoSecundario: string;
  DescSecundario: string;
}

export interface DadosPessoais {
  //etapa 1
  nomeCompleto: string;
  cpf: string;
  dataNascimento: Data;
  enderecoEmpresa: {
    Setor: string;
    CentroDeCusto: string;
  };
  jornadaDeTrabalho: {
    cargaHoraria: string;
    descanso: string;
    horario: string;
    turno: string;
  };
  sindicato: string;
  ocupacao: {
    cargo: string;
    dataAdmissao: Data;
    funcao: string;
    nivel: string;
    referencia: string;
    vinculo: string;
  };
  tipoFolha: string;
}

export interface DadosBasicos {
  sexo: Number;
  nomeDaMae: String;
  nomeDoPai: String;
  tipoSanguineo: String;
  corDePele: String;
  estadoCivil: Number;
  grauDeInstrucao: Number;
  nacionalidade: Number;
  ufNaturalidade: String;
  municipioNaturalidade: String;
  funcao: String;
}

export interface Documentos {
  //etapa 2
  Identidade: Identidade;
  CarteiraTrabalhista: CarteiraDeTrabalho;
  CarteiraDeHabilitacao: Cnh;
  Profissao: Profissao;
  TituloEleitor: TituloEleitor;
  Pis: Pis;
  CertificadoMilitar: CertificadoMilitar;
}

export interface EtapaContatoEndereco {
  //etapa3
  endereco: Endereco;
  contato: Contato;
  contatosSecundarios: ContatosSecundarios;
}

export interface EtapaPagamento {
  contratoTemporario: ContratoTemporario;
  periodoExperiencia: PeriodoExperiencia;
  adts: Adts;
  nomeacao: Nomeacao;
  diasUteis: string;
  tipoFolha: string;
  bloqueadoConsignado: boolean;
}

export interface ContratoTemporario {
  inicio: Data;
  final: Data;
}

export interface PeriodoExperiencia {
  inicio: Data;
  final: Data;
}

export interface Adts {
  dataUltimoBeneficioAdquirido: Data;
  opcao: string;
}

export interface Nomeacao {
  classificaoAto: string;
  numeroProcesso: string;
  ato: Ato;
  diarioOficial: DiarioOficial;
}

export interface Ato {
  numero: string;
  anoAto: number;
  data: Data;
}
export interface DiarioOficial {
  numero: number;
  dataDiarioOficial: Data;
}
export interface Horario {
  EntradaAntesAlmoco: string;
  SaidaAntesAlmoco: string;
  EntradaDepoisAlmoco: string;
  SaidaDepoisAlmoco: string;
}

export interface CredenciasEmails {
  Email: string;
  Cpf: string;
  CodigoFilial: number;
  Sigla: string;
  NomeFilial: string;
  Login: string;
  Senha: string;
}
export interface Usuario {
  EmpresaFilial: string;
  CodigoCliente: string;
  IsSenhaValida?: boolean;
  ExisteSenha?: boolean;
  SenhaAnterior?: string;
  Senha?: string;
  Login?: string;
  NumeroDeOrdem: number;
  NomeEmpresaFilial: string;
  Nome: string;
  Matricula?: number;
  Email: string;
  Cpf: string;
  CodigoUnico?: any;
  Tipo?: string;
  CodigoBloqueio?: string;
}
export interface DescontosCompulsorios {
  previdencia: boolean;
  impostoRenda: boolean;
  pensao: boolean;
}

export interface Flags {
  F1: string;
  F2: string;
  F3: string;
  F4: string;
  F5: string;
  F6: string;
  F7: string;
  F8: string;
  F9: string;
  F10: string;
  F11: string;
  F12: string;
  F13: string;
  F14: string;
  F15: string;
  F16: string;
  F17: string;
  F18: string;
  F19: string;
  F20: string;
  F21: string;
  F22: string;
  F23: string;
  F24: string;
  F25: string;
  F26: string;
  F27: string;
  F28: string;
  F29: string;
  F30: string;
}

export interface BuscarColaboradorInativo {
  cpf: string;
  empfil: string;
}
