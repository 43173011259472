<!-- Main sidebar -->
<div
  *ngIf="
    localStorage.TipoUsuario !== '1' && localStorage.TipoUsuario !== '10' && rotaAtual != '/login'
  "
  class="sidebar sidebar-dark sidebar-main sidebar-fixed sidebar-expand-md"
>
  <!-- Sidebar mobile toggler -->
  <div class="sidebar-mobile-toggler text-center">
    <a href="#" class="sidebar-mobile-main-toggle" id="botaoInibir">
      <i class="icon-arrow-left8"></i>
    </a>
    Navegação
    <a href="#" class="sidebar-mobile-expand">
      <i class="icon-screen-full"></i>
      <i class="icon-screen-normal"></i>
    </a>
  </div>
  <!-- /sidebar mobile toggler -->
  <!-- Sidebar content -->
  <div class="sidebar-content pb-5 pb-sm-5">
    <!-- // Main navigation // -->
    <div class="card card-sidebar-mobile">
      <ul
        class="nav nav-sidebar"
        data-nav-type="accordion"
        *ngFor="let item of menuNivel1; let i = index"
      >
        <!-- // Main // -->
        <li class="nav-item" *ngIf="i == 0">
          <a routerLink="home" class="nav-link" (click)="atualizarPagina('home')">
            <i class="icon-home4"></i>
            <span> Home </span>
          </a>
        </li>

        <li class="nav-item nav-item-submenu" *ngIf="temFilho(item)">
          <a href="#" class="nav-link" *ngIf="item.NivelDoItemDeMenu == 1"
            ><i class="{{ item.icon }}"></i> <span>{{ item.Descricao }}</span></a
          >

          <ul class="nav nav-group-sub h-100" id="submenu">
            <b *ngFor="let menuSubItem of menuNivel2">
              <b *ngIf="menuSubItem.NivelDoItemDeMenu != 1 && !temFilho(menuSubItem)">
                <li
                  class="nav-item"
                  *ngIf="
                    menuSubItem.NivelDoItemDeMenu == 2 &&
                    menuSubItem.linkExterno == 0 &&
                    menuSubItem.CodigoDoItemPai == item.CodigoDoItemDeMenu
                  "
                >
                  <a
                    routerLink="{{ menuSubItem.Link }}"
                    class="nav-link"
                    (click)="atualizarPagina(menuSubItem.Link)"
                    ><i class="{{ menuSubItem.icon }}"></i
                    ><span>{{ menuSubItem.Descricao }}</span></a
                  >
                </li>
                <li
                  class="nav-item"
                  *ngIf="
                    menuSubItem.NivelDoItemDeMenu == 2 &&
                    menuSubItem.linkExterno == 1 &&
                    menuSubItem.CodigoDoItemPai == item.CodigoDoItemDeMenu
                  "
                >
                  <a
                    href="{{ menuSubItem.Link }}"
                    target="new"
                    class="nav-link"
                    (click)="inibirMenu()"
                    ><i class="{{ menuSubItem.icon }}"></i
                    ><span>{{ menuSubItem.Descricao }}</span></a
                  >
                </li>
              </b>
              <b *ngIf="menuSubItem.NivelDoItemDeMenu != 1 && temFilho(menuSubItem)">
                <li
                  class="nav-item nav-item-submenu"
                  *ngIf="
                    menuSubItem.NivelDoItemDeMenu == 2 &&
                    menuSubItem.linkExterno == 0 &&
                    menuSubItem.CodigoDoItemPai == item.CodigoDoItemDeMenu
                  "
                >
                  <a href="#" class="nav-link"
                    ><i class="{{ menuSubItem.icon }}"></i
                    ><span>{{ menuSubItem.Descricao }}</span></a
                  >

                  <ul class="nav nav-group-sub h-100 z-20">
                    <b *ngFor="let menuSubItemD of menuNivel3">
                      <li
                        class="nav-item"
                        *ngIf="
                          menuSubItemD.NivelDoItemDeMenu == 3 &&
                          menuSubItemD.linkExterno == 0 &&
                          menuSubItemD.CodigoDoItemPai == menuSubItem.CodigoDoItemDeMenu
                        "
                      >
                        <a
                          routerLink="{{ menuSubItemD.Link }}"
                          class="nav-link"
                          (click)="atualizarPagina(menuSubItemD.Link)"
                          ><i class="{{ menuSubItemD.icon }}"></i
                          ><span>{{ menuSubItemD.Descricao }}</span></a
                        >
                      </li>
                      <li
                        class="nav-item"
                        *ngIf="
                          menuSubItemD.NivelDoItemDeMenu == 3 &&
                          menuSubItemD.linkExterno == 1 &&
                          menuSubItemD.CodigoDoItemPai == menuSubItem.CodigoDoItemDeMenu
                        "
                      >
                        <a
                          href="{{ menuSubItemD.Link }}"
                          target="new"
                          class="nav-link"
                          (click)="inibirMenu()"
                          ><i class="{{ menuSubItemD.icon }}"></i
                          ><span>{{ menuSubItemD.Descricao }}</span></a
                        >
                      </li>
                    </b>
                  </ul>
                </li>
              </b>
            </b>
          </ul>
        </li>

        <li class="nav-item nav-item" *ngIf="!temFilho(item) && item.NivelDoItemDeMenu == 1">
          <a
            routerLink="{{ item.Link }}"
            *ngIf="item.linkExterno == 0"
            class="nav-link"
            (click)="atualizarPagina(item.Link)"
            ><i class="{{ item.icon }}"></i> <span>{{ item.label }}</span></a
          >
          <a
            href="{{ item.Link }}"
            target="new"
            *ngIf="item.linkExterno == 1"
            class="nav-link"
            (click)="atualizarPagina(item.Link)"
            ><i class="{{ item.icon }}"></i> <span>{{ item.label }}</span></a
          >
        </li>
        <!-- main -->
      </ul>
    </div>
    <!-- main navigation -->
  </div>
  <!-- sidebar content  -->
</div>
