import { LancamentoFinanceiroComponent } from './componentes/lancamento-financeiro/lancamento-financeiro.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { CadastroComponent } from './componentes/cadastro/cadastro.component';
import { CadastroPendenteComponent } from './componentes/cadastro-pendente/cadastro-pendente.component';
import { ProfileComponent } from './componentes/profile/profile.component';
import { LoginComponent } from './componentes/login/login.component';
import { RecuperarSenhaComponent } from './componentes/login/recuperar-senha/recuperar-senha.component';
import { AlterarSenhaComponent } from './componentes/login/alterar-senha/alterar-senha.component';
import { HomePageComponent } from './componentes/home-page/home-page.component';
import { TransferenciaFuncionarioComponent } from './componentes/transferencia-funcionario/transferencia-funcionario.component';
import { ColaboradoresComponent } from './componentes/colaboradores/colaboradores.component';
import { CommonModule } from '@angular/common';
import { ErroComponent } from './componentes/erro/erro.component';
import { ScatComponent } from './componentes/scat/scat.component';
import { FeriasComponent } from './componentes/ferias/ferias.component';
import { RelatoriosComponent } from './componentes/relatorios/relatorios.component';
import { GerencialComponent } from './componentes/gerencial/gerencial.component';
import { RecrutamentoComponent } from './componentes/recrutamento/recrutamento.component';
import { RescisaoComponent } from './componentes/rescisao/rescisao.component';
import { ContinuacaoCadastroComponent } from './componentes/continuacao-cadastro/continuacao-cadastro.component';
import { QuadroBeneficiosComponent } from './componentes/beneficios/quadro-beneficios/quadro-beneficios.component';
import { PensaoAlimenticiaComponent } from './componentes/pensao-alimenticia/pensao-alimenticia.component';
import { RegrasBeneficioComponent } from './componentes/beneficios/regras-beneficio/regras-beneficio.component';
import { EdicaoBeneficioComponent } from './componentes/beneficios/regras-beneficio/edicao-beneficio/edicao-beneficio.component';
import { TipoBeneficiarioComponent } from './componentes/beneficios/tipo-beneficiario/tipo-beneficiario.component';
import { GrupoBeneficioComponent } from './componentes/beneficios/grupo-beneficio/grupo-beneficio.component';
import { CadastroBeneficioComponent } from './componentes/beneficios/quadro-beneficios/cadastro-beneficio/cadastro-beneficio.component';
import { CancelarRescisaoComponent } from './componentes/cancelar-rescisao/cancelar-rescisao.component';
import { RelatorioSinteticoComponent } from './componentes/beneficios/relatorio-sintetico/relatorio-sintetico.component';
import { CadastroPublicoComponent } from './componentes/cadastro-publico/cadastro-publico.component';
import { SituacaoFuncionalComponent } from './componentes/situacao-funcional/situacao-funcional.component';
import { SituacaoFuncionalFalecimentoComponent } from './componentes/situacao-funcional-falecimento/situacao-funcional-falecimento.component';
import { ProcessosComponent } from './componentes/processos/processos.component';
import { ContrachequeComponent } from './componentes/contracheque/contracheque.component';
import { MigracaoComponent } from './componentes/migracao/migracao.component';
import { PensaoPrevidenciariaComponent } from './componentes/pensao-previdenciaria/pensao-previdenciaria.component';
import { ParametrosComponent } from './componentes/parametros/parametros.component';
import { CadastroTipoUsuarioComponent } from './componentes/pse/cadastro-tipo-usuario/cadastro-tipo-usuario.component';
import { PseColaboradoresComponent } from './componentes/pse/pse-colaboradores/pse-colaboradores.component';
import { CadastroCessaoComponent } from './componentes/complemento-cadastral/cadastro-cessao/cadastro-cessao.component';
import { CadastroEstagioComponent } from './componentes/complemento-cadastral/cadastro-estagio/cadastro-estagio.component';
import { CadastroAprendizComponent } from './componentes/complemento-cadastral/cadastro-aprendiz/cadastro-aprendiz.component';
import { CadastroContratoExperienciaComponent } from './componentes/complemento-cadastral/cadastro-contrato-experiencia/cadastro-contrato-experiencia.component';
import { FeriasSetorComponent } from './componentes/ferias/Componentes/relatorio-ferias-setor/relatorio-ferias-setor.component';
import { CadastroClienteComponent } from './componentes/pse/cadastro-cliente/cadastro-cliente.component';
import { TarefasComponent } from './componentes/pse/tarefas/tarefas.component';
import { ManutencaoBdLogicosComponent } from './componentes/pse/cadastro-cliente/servicos-cliente/manutencao-bd-logicos/manutencao-bd-logicos.component';
import { ManutencaoBdFisicosComponent } from './componentes/pse/cadastro-cliente/servicos-cliente/manutencao-bd-fisicos/manutencao-bd-fisicos.component';
import { TransferenciaInativoComponent } from './componentes/transferencia-inativo/transferencia-inativo.component';
import { CadastroUsuarioComponent } from './componentes/pse/usuario/cadastro-usuario/cadastro-usuario.component';
import { ServicosComponent } from './componentes/pse/servicos/servicos.component';
import { ItemMenuComponent } from './componentes/pse/item-menu/item-menu.component';
import { PsePermisaoEmpresaFilialComponent } from './componentes/pse/pse-permisao-empresa-filial/pse-permisao-empresa-filial.component';
import { ManutencaoServicosClienteComponent } from './componentes/pse/cadastro-cliente/servicos-cliente/manutencao-servicos-cliente/manutencao-servicos-cliente.component';
import { ConfiguracaoClienteComponent } from './componentes/pse/configuracao/configuracao-cliente/configuracao-cliente.component';
import { MainComponent } from './componentes/main/main.component';
import { AppComponent } from './app.component';

// import { CadastroTelaComponent } from './componentes/cadastro-tela/cadastro-tela.component';
// import { EsocialComponent } from './componentes/esocial/esocial/esocial.component';
// import { CancelarRescisaoComponent } from './componentes/cancelar-rescisao/cancelar-rescisao.component';
// import { ControlePontoComponent } from './componentes/controle-ponto/controle-ponto.component';
// import { ComplementoCadastroComponent } from './componentes/complemento-cadastro/complemento-cadastro.component';
// import { ListaUsuarioComponent } from './componentes/pse/usuario/lista-usuario/lista-usuario.component';
// import { ControleColaboradorComponent } from './componentes/controle-ponto/controle-colaborador/controle-colaborador.component';
// import { PermissaoEmpresaFilialComponent } from './componentes/pse/permissao-empresa-filia/permissao-empresa-filial.component';
// import { ControleSupervisorComponent } from './componentes/controle-ponto/controle-supervisor/controle-supervisor.component';
// import { ControleRhComponent } from './componentes/controle-ponto/controle-rh/controle-rh.component';
// import { AssinaturaAnexoComponent } from './componentes/assinatura-anexo/assinatura-anexo.component';
// import { ControlePontoRhGuard } from './auth/guard/controle-ponto-rh-guard.guard';
// import { PesquisarMatriculaComponent } from './componentes/pesquisar-matricula/pesquisar-matricula.component';
import { AvisosComponent } from './componentes/ficha-funcional/avisos/avisos.component';
import { ControleColaboradorComponent } from './componentes/controle-ponto/controle-colaborador/controle-colaborador.component';
import { GraficoBeneficioComponent } from './componentes/beneficios/grafico-beneficio/grafico-beneficio.component';
import { ElogiosEPenalidadesComponent } from './componentes/ficha-funcional/elogios-e-penalidades/elogios-e-penalidades.component';
import { TransferenciaHistoricoComponent } from './componentes/transferencia-historico/transferencia-historico.component';
import { AlertaModalComponent } from './componentes/componentes-funcionais/alerta-modal/alerta-modal.component';
import { ColaboradoresHomeComponent } from './componentes/colaboradores/colaboradores-home/colaboradores-home.component';
import { ColaboradorComponent } from './componentes/colaboradores/colaborador/colaborador.component';
import { CaracteristicasPessoaisComponent } from './componentes/caracteristicas-pessoais/caracteristicas-pessoais.component';
import { SaudeComponent } from './componentes/saude/saude.component';
import { AtualizarComplementoCadastralComponent } from './componentes/atualizar-complemento-cadastral/atualizar-complemento-cadastral.component';
import { ListagemCensoComponent } from './componentes/censo/listagem-censo/listagem-censo.component';
import { CensoConvocacaoComponent } from './componentes/censo/censo-convocacao/censo-convocacao.component';
import { CensoPendentesComponent } from './componentes/censo/censo-pendentes/censo-pendentes.component';
import { ComplementoCadastralComponent } from './componentes/complemento-cadastral/complemento-cadastral.component';
import { FaltasComponent } from './componentes/faltas/faltas.component';
import { PromocoesComponent } from './componentes/promocoes/promocoes.component';
import { TempoServicoComponent } from './componentes/tempo-servico/tempo-servico.component';
import { ObservacoesDiversasComponent } from './componentes/observacoes-diversas/observacoes-diversas.component';
import { ExperienciaProfissionalComponent } from './componentes/experiencia-profissional/experiencia-profissional.component';
import { FormacaoBasicaComponent } from './componentes/formacao-basica/formacao-basica.component';
import { ComissaoComponent } from './componentes/comissao/comissao.component';
import { LicencaPremioComponent } from './componentes/licenca-premio/licenca-premio.component';
import { ProntuarioMedicoComponent } from './componentes/prontuario-medico/prontuario-medico.component';
import { CadastroComplementarComponent } from './componentes/informacoes-complementares/cadastro-complementar/cadastro-complementar.component';
import { DadosBeneficioComponent } from './componentes/informacoes-complementares/dados-beneficio/dados-beneficio.component';
import { InformacoesConcursoComponent } from './componentes/informacoes-complementares/informacoes-concurso/informacoes-concurso.component';
import { InformacoesSaudeComponent } from './componentes/informacoes-complementares/informacoes-saude/informacoes-saude.component';
import { NomeSocialComponent } from './componentes/informacoes-complementares/nome-social/nome-social.component';
import { ProfissionalEducacaoComponent } from './componentes/informacoes-complementares/profissional-educacao/profissional-educacao.component';
import { OutrasDocumentacoesComponent } from './componentes/outras-documentacoes/outras-documentacoes.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'login/recuperar-senha', component: RecuperarSenhaComponent },
  { path: 'alterar-senha/:dados', component: AlterarSenhaComponent },
  {
    path: '',
    component: HomePageComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  { path: 'cadastro', component: CadastroComponent, canActivate: [AuthGuard] },
  {
    path: 'cadastro-publico',
    component: CadastroPublicoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cadastro-pendente/:numOrdem',
    component: CadastroPendenteComponent,
    canActivate: [AuthGuard],
  },
  { path: 'home', component: HomePageComponent, canActivate: [AuthGuard] },
  {
    path: 'transferencia',
    component: TransferenciaFuncionarioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'perfil/:numOrdem',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'colaboradores/colaborador',
    component: ColaboradorComponent,
    canActivate: [AuthGuard],
  },
  // { path: 'colaboradores', component: ColaboradoresComponent, canActivate: [AuthGuard] },
  {
    path: 'colaboradores',
    component: ColaboradoresComponent,
    canActivate: [AuthGuard],
  },
  { path: 'erro', component: ErroComponent, canActivate: [AuthGuard] },
  { path: 'scat', component: ScatComponent, canActivate: [AuthGuard] },
  {
    path: 'relatorios',
    component: RelatoriosComponent,
    canActivate: [AuthGuard],
  },
  { path: 'ferias', component: FeriasComponent, canActivate: [AuthGuard] },
  {
    path: 'pensao-previdencia',
    component: PensaoPrevidenciariaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'gerencial',
    component: GerencialComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'recrutamento',
    component: RecrutamentoComponent,
    canActivate: [AuthGuard],
  },
  { path: 'rescisao', component: RescisaoComponent, canActivate: [AuthGuard] },
  {
    path: 'continuacao-cadastro/:codTarefa/:tpProc/:empfil/:admUser',
    component: ContinuacaoCadastroComponent,
  },
  {
    path: 'cancelar-rescisao',
    component: CancelarRescisaoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'beneficios/quadro-beneficios',
    component: QuadroBeneficiosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'beneficios/quadro-beneficios/cadastro',
    component: CadastroBeneficioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'beneficios/regras-beneficio',
    component: RegrasBeneficioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'beneficios/regras-beneficio/editar/:Matricula',
    component: EdicaoBeneficioComponent,
  },
  {
    path: 'beneficios/tipo-beneficiario',
    component: TipoBeneficiarioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'beneficios/grupo-beneficio',
    component: GrupoBeneficioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'beneficios/relatorio-sintetico',
    component: RelatorioSinteticoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'beneficios/grafico-beneficio',
    component: GraficoBeneficioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'continuacao-cadastro/:codTarefa/:tpProc/:empfil/:admUser',
    component: ContinuacaoCadastroComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'migracao/:id',
    component: MigracaoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'situacao-funcional',
    component: SituacaoFuncionalComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'situacao-funcional/falecimento',
    component: SituacaoFuncionalFalecimentoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'processos',
    component: ProcessosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'contracheque',
    component: ContrachequeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pensao-alimenticia',
    component: PensaoAlimenticiaComponent,
    // canActivate: [AuthGuard],
  },
  { path: 'flags', component: ParametrosComponent, canActivate: [AuthGuard] },
  {
    path: 'complemento-cadastral/cessao',
    component: ComplementoCadastralComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'complemento-cadastral/cessao/cadastro',
    component: CadastroCessaoComponent,
    // canActivate: [AuthGuard],
    // auth comentados em todos os cadastros do complemento cadastral para revisão de navegação
  },
  {
    path: 'complemento-cadastral/estagio',
    component: ComplementoCadastralComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'complemento-cadastral/estagio/cadastro',
    component: CadastroEstagioComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'complemento-cadastral/aprendiz',
    component: ComplementoCadastralComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'complemento-cadastral/aprendiz/cadastro',
    component: CadastroAprendizComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'complemento-cadastral/contrato-experiencia',
    component: ComplementoCadastralComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'complemento-cadastral/contrato-experiencia/cadastro',
    component: CadastroContratoExperienciaComponent,
    // canActivate: [AuthGuard],
  },

  {
    path: 'lancamento-financeiro',
    component: LancamentoFinanceiroComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ferias/relatoriosetor',
    component: FeriasSetorComponent,
    canActivate: [AuthGuard],
  },
  { path: 'transferencia-inativo', component: TransferenciaInativoComponent, canActivate: [AuthGuard] },
  {
    path: 'pse/cadastro-tipo-usuario',
    component: CadastroTipoUsuarioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pse/cadastro-cliente',
    component: CadastroClienteComponent,
    canActivate: [AuthGuard],
  },
  { path: 'pse/cadastro-tarefas', component: TarefasComponent },
  {
    path: 'pse/cadastro-cliente/manutencao-bd-logicos',
    component: ManutencaoBdLogicosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pse/cadastro-cliente/manutencao-bd-fisicos',
    component: ManutencaoBdFisicosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pse/cadastro-usuario',
    component: CadastroUsuarioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pse/cadastro-servico',
    component: ServicosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pse/item-menu',
    component: ItemMenuComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pse/colaboradores',
    component: PseColaboradoresComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ficha-funcional/avisos',
    component: AvisosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ficha-funcional/elogios-penalidades',
    component: ElogiosEPenalidadesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'transferencia-historico',
    component: TransferenciaHistoricoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'teste',
    component: CaracteristicasPessoaisComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'saude/:matricula',
    component: SaudeComponent,
    canActivate: [AuthGuard],
  },
  { path: 'censo/anual', component: CensoConvocacaoComponent, canActivate: [AuthGuard] },
  { path: 'censo/aniversario', component: CensoConvocacaoComponent, canActivate: [AuthGuard] },
  { path: 'censo/listagem', component: ListagemCensoComponent, canActivate: [AuthGuard] },
  { path: 'censo/pendentes', component: CensoPendentesComponent, canActivate: [AuthGuard] },
  { path: 'faltas-trabalho', component: FaltasComponent, canActivate: [AuthGuard] },
  { path: 'promocoes', component: PromocoesComponent, canActivate: [AuthGuard] },
  { path: 'tempo-servico', component: TempoServicoComponent, canActivate: [AuthGuard] },
  { path: 'obs-diversas', component: ObservacoesDiversasComponent, canActivate: [AuthGuard] },
  { path: 'licenca-premio', component: LicencaPremioComponent, canActivate: [AuthGuard] },
  { path: 'prontuario-medico', component: ProntuarioMedicoComponent, canActivate: [AuthGuard] },
  { path: 'outras-documentacoes', component: OutrasDocumentacoesComponent, canActivate: [AuthGuard]},
  {
    path: 'atualizar-complemento-cadastral',
    component: AtualizarComplementoCadastralComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'comissao',
    component: ComissaoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'experiencia-profissional',
    component: ExperienciaProfissionalComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'formacao-basica-especializacao',
    component: FormacaoBasicaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'comissao',
    component: ComissaoComponent,
    // canActivate: [AuthGuard],
  },

  {
    path: 'experiencia-profissional',
    component: ExperienciaProfissionalComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'formacao-basica-especializacao',
    component: FormacaoBasicaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'comissao',
    component: ComissaoComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'cadastro-complementar',
    component: CadastroComplementarComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'dados-beneficio',
    component: DadosBeneficioComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'informacoes-concurso',
    component: InformacoesConcursoComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'informacoes-saude',
    component: InformacoesSaudeComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'nome-social',
    component: NomeSocialComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'profissional-educacao',
    component: ProfissionalEducacaoComponent,
    // canActivate: [AuthGuard],
  },

  //{ path: 'teste3', component: AtualizarComplementoCadastralComponent},

  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
