import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { formFichaFinanceira } from './relatorios-perfil.component';

@Injectable({
  providedIn: 'root',
})
export class RelatoriosPerfilService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  cabecalho: any;
  filial: string;
  url_acesso: any = this.acesso.validarUrl();
  competencia: string;

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
    const date = new Date();
    this.competencia = `${date.getFullYear().toString()}${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}`;
  }

  buscarDadosPagamento(usuario: Cadastro) {
    return this.http.get(
      `${this.url_acesso}relatorio/dados-pagamento/${this.competencia}/EM/${this.filial}/${usuario.Matricula}`,
      { headers: this.cabecalho }
    );
  }

  buscarFichaFinanceira(usuario: Cadastro, dadosForm: formFichaFinanceira) {
    return this.http.get(
      `${this.url_acesso}relatorio/ficha-financeira/${dadosForm.modoImpressao}/${dadosForm.dtInicio}/${dadosForm.dtFim}/EM/${this.filial}/${usuario.Matricula}/${dadosForm.modeloImpressao}`,
      { headers: this.cabecalho }
    );
  }
}
