import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoginService } from './login.service';
import { Route, Router } from '@angular/router';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert';
//import { Filial } from '../transferencia-funcionario/classes/classes.component';
import { AuthService } from 'src/app/auth/auth.service';
import { Location } from '@angular/common';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Filial } from 'src/app/service/classes/localstorage/Filial';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';
import { CadastroService } from '../cadastro/cadastro.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  //Ambiente => PROD ou DEV
  login = { Usuario: '', Senha: '', Ip: '', Os: '', Browser: '', Ambiente: '' };
  servico = { ip: '' };
  info: Login;
  info_cliente: Usuario;
  loading: boolean = false;
  formLogin: any;
  formLoginComplementar: UntypedFormGroup;
  recaptcha: any[];
  validaCliente: boolean = false;
  validaServico: boolean = false;
  revelarOlho: boolean = false;
  sessao: string;
  localStorage: LocalStorage;
  matriculaColaboradorInativo: number;
  //captchaResolved: boolean = false;

  constructor(
    private location: Location,
    private loginService: LoginService,
    private cadastroPublicoService: CadastroService,
    private myRoute: Router,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    public auth: AuthService
  ) {}

  ngOnInit() {
    localStorage.clear();
    scroll(50, 50);
    this.formLogin = this.formBuilder.group({
      login: ['', Validators.compose([Validators.required])],
      senha: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
      recaptchaReactive: [''], //, Validators.compose(window.location.href.includes('localhost') ? null : [Validators.required])]
    });
    this.formLoginComplementar = this.formBuilder.group({
      cliente: [''],
      servico: [''],
    });
    this.buscarIp();
  }

  checkCaptcha(captchaResponse: string) {
    //this.captchaResolved = (captchaResponse && captchaResponse.length > 0) ? true : false;
  }

  buscarIp() {
    this.loginService.buscarIp().subscribe(
      (data) => {
        this.servico = JSON.parse(JSON.stringify(data));
        this.login.Ip = this.servico.ip;
      },
      (error) => {
        this.login.Ip = '0';
      }
    );
  }

  LoginMask(): string {
    const loginValue = this.formLogin.get('login').value;

    if (/^\d+$/.test(loginValue)) {
      return '000.000.000-00';
    }
    return '';
  }

  selecionarClienteServico(tipo: boolean) {
    const { cliente, servico } = this.formLoginComplementar.getRawValue();
    if (tipo && !this.validaServico) {
      this.logarClienteServiço(cliente, this.info.Usuario.ServicoSelecionado.Id);
    } else if (!tipo && !this.validaCliente) {
      this.logarClienteServiço(String(this.info.Usuario.ClienteSelecionado.IdCliente), servico);
    } else {
      cliente && servico ? this.logarClienteServiço(cliente, servico) : '';
    }
  }

  recuperarSenha() {
    this.myRoute.navigate(['login/recuperar-senha']);
  }

  errored() {
    console.warn(`reCAPTCHA error encountered`);
  }

  logar(dados) {
    //
    this.loading = true;

    this.login.Os = this.loginService.buscarOs();
    this.login.Browser = this.loginService.buscarBrowser();
    this.login.Usuario = dados.value.login;
    this.login.Senha = dados.value.senha;
    this.login.Ambiente = window.location.href.includes('localhost') ? 'DEV' : 'PROD';
    //
    this.loginService.postLogar(this.login).subscribe(
      (data: any) => {
        if (data.Usuario.CodigoBloqueio === '0') {
          this.info = JSON.parse(JSON.stringify(data));

          if (this.info.Usuario.Clientes.length > 1 || this.info.Usuario.Servicos.length > 1) {
            this.sessao = this.info.Sessao;
            this.info.Usuario.Clientes.length > 1
              ? (this.validaCliente = true)
              : (this.validaCliente = false);
            this.info.Usuario.Servicos.length > 1
              ? (this.validaServico = true)
              : (this.validaServico = false);
          } else {
            localStorage.setItem(
              'util',
              new Crypto().encryptUsingAES256(JSON.stringify(this.montarLocalStorage(this.info)))
            );

            /*localStorage.setItem("sessao", this.info.Sessao);
              localStorage.setItem("codigoUsuario", this.info.Usuario.CodigoUnico)
              localStorage.setItem("menu", JSON.stringify(this.info.Usuario.Menu));
              localStorage.setItem("nome", this.info.Usuario.Nome);
              localStorage.setItem("matricula_usuario", this.info.Usuario.Matricula.toString());
              localStorage.setItem("tipoUsuario", this.info.Usuario.Tipo);
              localStorage.setItem("numOrd", this.info.Usuario.NumeroDeOrdem.toString());
              localStorage.setItem("clienteId", data.Usuario.ClienteSelecionado.IdCliente);
              localStorage.setItem("codigoServico", data.Usuario.ServicoSelecionado.Id)

              // Seta a filial atual e salva as filiais
              localStorage.setItem("filiais", JSON.stringify(this.info.Usuario.ClienteSelecionado.Filiais));
              localStorage.setItem("clientePublico", JSON.stringify(this.info.Usuario.ClienteSelecionado['EmpresaPublica']));
              localStorage.setItem("filial_atual", String(this.info.Usuario.ClienteSelecionado.Filiais[0].Codigo));
              localStorage.setItem("filialPrevidenciaria", String(data.Usuario.ClienteSelecionado.Filiais[0].EmpresaPrevidenciaria));
              localStorage.setItem("nome_filial_atual", String(this.info.Usuario.ClienteSelecionado.Filiais[0].Nome));

              localStorage.setItem("toogle", "1");
              localStorage.setItem("loading", 'true');

              localStorage.setItem("codigoServico", data.Usuario.ServicoSelecionado.Id)*/
            this.loading = false;
            this.auth.verificaLogin();
            if (this.info.Usuario.Tipo == '1')
              window.location.href = `/perfil/${this.info.Usuario.NumeroDeOrdem}`; //
            else window.location.href = '';
          }
        } else {
          this.loading = false;
          this.formLogin.controls['recaptchaReactive'].setValue('');
          new AlertaModal(
            'alerta',
            'Usuário Bloqueado',
            'Por favor, procurar o administrador do sistema.',
            'p'
          );
        }
      },
      (error) => {
        //alert(this.formLogin.invalid);
        this.formLogin.controls['recaptchaReactive'].setValue('');
        if (error.status == 401) {
          new AlertaModal('alerta', 'Login inválido!', error.error.Mensagem, 'p');
          this.loading = false;
        } else {
          new AlertaModal(
            'erro',
            'Não foi possível realizar o login!',
            'Algo ocorreu, tente novemante mais tarde.',
            'p'
          );
          this.loading = false;
        }
      }
    );
  }

  logarClienteServiço(codigoClinete: string, codigoServico: string) {
    this.spinner.show();
    if (codigoClinete === '' || codigoServico === '') {
      new AlertaModal('erro', 'Selecione uma opção!', 'Por favor, selecione as duas opçoes.', 'p');
    } else {
      this.loginService
        .postLogarCliente(this.sessao, this.info.Usuario.CodigoUnico, codigoClinete, codigoServico)
        .subscribe(
          (data: any) => {
            this.info.Sessao = this.sessao;
            this.info.Usuario = JSON.parse(JSON.stringify(data));

            localStorage.setItem(
              'util',
              new Crypto().encryptUsingAES256(JSON.stringify(this.montarLocalStorage(this.info)))
            );
            /*this.montarLocalStorage(JSON.parse(JSON.stringify(data)));
            //this.spinner.hide();
            this.info_cliente = JSON.parse(JSON.stringify(data));
            localStorage.setItem("filialPrevidenciaria",String(data.ClienteSelecionado.Filiais[0].EmpresaPrevidenciaria));
            this.info_cliente = JSON.parse(JSON.stringify(data));
            localStorage.setItem("clienteId", data.ClienteSelecionado.IdCliente);
            localStorage.setItem("codigoServico", data.ServicoSelecionado.Id)
    
            localStorage.setItem("sessao",this.sessao);     
            localStorage.setItem("codigoUsuario", this.info_cliente.CodigoUnico)   
            localStorage.setItem("menu",JSON.stringify(this.info_cliente.Menu));     
            localStorage.setItem("nome",this.info_cliente.Nome);
            // Seta a filial atual e salva as filiais
            localStorage.setItem("filiais",JSON.stringify(this.info_cliente.ClienteSelecionado.Filiais));
            localStorage.setItem("matricula_usuario",this.info.Usuario.Matricula.toString());
            localStorage.setItem("tipoUsuario", this.info.Usuario.Tipo);
            localStorage.setItem("numOrd", this.info.Usuario.NumeroDeOrdem.toString());
            localStorage.setItem("clientePublico", String(this.info_cliente.ClienteSelecionado.EmpresaPublica));
    
    
            localStorage.setItem("filial_atual",String(this.info_cliente.ClienteSelecionado.Filiais[0].Codigo));
            localStorage.setItem("nome_filial_atual",String(this.info_cliente.ClienteSelecionado.Filiais[0].Nome));
            localStorage.setItem("filialPrevidenciaria",String(data.ClienteSelecionado.Filiais[0].EmpresaPrevidenciaria));
    
            localStorage.setItem("toogle","1");
            localStorage.setItem("loading",'true');*/
            this.auth.verificaLogin();

            if (this.info.Usuario.Tipo == '1')
              window.location.href = `/perfil/${this.info.Usuario.NumeroDeOrdem}`;
            else window.location.href = '';
          },
          (error) => {
            this.spinner.hide();

            if (error.status == 401) {
              new AlertaModal('alerta', 'Login inválido!', error.error.Mensagem, 'p');
              this.loading = false;
              //  location.reload()
            } else {
              new AlertaModal(
                'alerta',
                'Não foi possível realizar o login!',
                'Algo ocorreu, tente novemante mais tarde.',
                'p'
              );
              this.loading = false;
              //  location.reload()
            }
            error.error.Redirect === 1 ? location.reload() : null;
          }
        );
    }
  }

  alterarLocalStoareg() {
    localStorage.setItem('sessao', this.info.Sessao);
    localStorage.setItem('codigoUsuario', this.info.Usuario.CodigoUnico);
    localStorage.setItem('menu', JSON.stringify(this.info.Usuario.Menu));
    localStorage.setItem('nome', this.info.Usuario.Nome);
    localStorage.setItem('matricula_usuario', this.info.Usuario.Matricula.toString());
    localStorage.setItem('tipoUsuario', this.info.Usuario.Tipo);
    localStorage.setItem('numOrd', this.info.Usuario.NumeroDeOrdem.toString());

    // Seta a filial atual e salva as filiais
    localStorage.setItem('filiais', JSON.stringify(this.info.Usuario.ClienteSelecionado.Filiais));
    localStorage.setItem(
      'clientePublico',
      JSON.stringify(this.info.Usuario.ClienteSelecionado.EmpresaPublica)
    );
    localStorage.setItem(
      'filial_atual',
      String(this.info.Usuario.ClienteSelecionado.Filiais[0].Codigo)
    );
    localStorage.setItem(
      'nome_filial_atual',
      String(this.info.Usuario.ClienteSelecionado.Filiais[0].Nome)
    );

    localStorage.setItem('toogle', '1');
    localStorage.setItem('loading', 'true');

    this.auth.verificaLogin();
    if (this.info.Usuario.Tipo == '1')
      window.location.href = `/perfil/${this.info.Usuario.NumeroDeOrdem}`; //
    else window.location.href = '';
  }

  formataCpf(cpf) {
    cpf = cpf.replace(/\D/g, '');

    while (cpf.length < 11) {
      cpf = '0' + cpf;
    }

    return cpf;
  }

  montarLocalStorage(login: Login): LocalStorage {
    let local = new LocalStorage();

    local.Ambiente = window.location.href.includes('localhost') ? 'DEV' : 'PROD';
    local.ClienteId = login.Usuario.ClienteSelecionado.IdCliente;
    local.ClientePublico = login.Usuario.ClienteSelecionado.EmpresaPublica;
    local.CodigoServico = login.Usuario.ServicoSelecionado.Id;
    local.CodigoUsuario = login.Usuario.CodigoUnico;
    local.Filiais = login.Usuario.ClienteSelecionado.Filiais.sort((a, b) => a.Codigo - b.Codigo);
    local.FilialAtual = login.Usuario.ClienteSelecionado.Filiais[0].Codigo;
    local.FilialPrevidenciaria = login.Usuario.ClienteSelecionado.Filiais[0].EmpresaPrevidenciaria;
    local.Loading = true;
    local.Login = false;
    local.MatriculaUsuario = login.Usuario.Matricula;
    local.Menu = login.Usuario.Menu;
    local.NomeFilialAtual = login.Usuario.ClienteSelecionado.Filiais[0].Nome;
    local.NomeUsuario = login.Usuario.Nome;
    local.NumeroOrdemUsuario = login.Usuario.NumeroDeOrdem;
    local.Sessao = login.Sessao;
    local.TipoUsuario = login.Usuario.Tipo;
    local.Toogle = '1';
    local.Cpf = this.formataCpf(login.Usuario.Cpf);

    return local;
  }

  revelarSenha(): void {
    let input = document.querySelector('#password');
    if (input.getAttribute('type') == 'password') {
      input.setAttribute('type', 'text');
      this.revelarOlho = true;
    } else {
      input.setAttribute('type', 'password');
      this.revelarOlho = false;
    }
  }

  Enviar() {
    new AlertaModal('sucesso', 'ola mundo!', 'Veja no seu e-mail.', 'P');
  }

  // validarLogin(){
  //   let el: HTMLElement = document.getElementById('evento_captcha');
  //   el.click();
  // }
  //  resolved(captchaResponse: string) {
  //  }
  // async resolved(recaptchaResponse: any[]){
  //   this.recaptcha = await recaptchaResponse;
  //   this.logar(this.formLogin)
  // }
}

export interface Login {
  Sessao: string;
  Usuario: Usuario;
}

export interface Usuario {
  ClienteSelecionado: Cliente;
  Clientes: Cliente[];
  CodigoBloqueio: number;
  CodigoUnico: string;
  Cpf: string;
  DescricaoTipo: string;
  Email: string;
  ExisteSenha: boolean;
  IsSenhaValida: boolean;
  Login: string;
  Matricula: number;
  Menu: [];
  Nome: string;
  NumeroDeOrdem: number;
  Senha: string;
  SenhaAnterior?: string;
  ServicoSelecionado: Servico;
  Servicos: Servico[];
  Tipo: string;
}

export interface Cliente {
  EmpresaPublica: boolean;
  Filiais: Filial[];
  IdCliente: number;
  Nome: string;
}

export interface Servico {
  DenominacaoCliente: string;
  Descricao: string;
  Id: string;
  Prefixo: string;
}
