import { evento } from './../home-page/home-page.component';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  SimpleChange,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, NgForm, Validators } from '@angular/forms';
import { AnexoService } from './anexo.service';
import { ErroService } from 'src/app/service/erros/erro.service';
import Swal from 'sweetalert';
import { Data } from 'src/app/service/interfaces/Data';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { ServerDate } from 'src/app/service/date/serverdate.service';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { DocumentosService } from '../profile/componentes/documentos-perfil/documentos-perfil.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { last, skip, take } from 'rxjs/operators';
import { Permissao } from 'src/app/service/permissoes/permissoes.service';

@Component({
  selector: 'app-anexo',
  templateUrl: './anexo.component.html',
  styleUrls: ['./anexo.component.css'],
})
export class AnexoComponent implements OnInit {
  localStorage: LocalStorage;

  formAnexo: FormGroup;

  @Output() gatilhoAtivado = new EventEmitter<any>();
  @ViewChild('inputAdicionar') inputAdicionar;
  @Output() listaPendencias = new EventEmitter<Array<any>>();
  @Output() listaAnexoCadastro = new EventEmitter<Array<Anexo>>();
  @Input() pagina: string;
  @Input() matricula: any;
  @Input() nomeUsuario: string;
  @Input() enviarSeparadamente: boolean;
  @Input() compChave: any = '';
  @Input() perfil: boolean;
  @Input() emailColaborador: string;
  @Input() opcional: boolean;
  @Input() notHaveCpf: boolean;
  @Input() listaProcessos: boolean;
  @Input() anexoSexo: number;
  @Input() anexoCarteira: number;
  @Input() anexoCasamento: number;
  @Input() desabilitado: boolean;
  @Input() permiteExclusao: boolean = false;
  @Input() modal: boolean = false;
  @Input() exportarParaObjeto: boolean = false;
  @Input() permissoes: Permissao[];
  podeEditar = true;
  listaTipoArquivo: any[] = [];
  NumeroDeOrdem: string;
  meses: string[] = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  listaAnexo: Anexo[] = [];
  ListaArquivos: Arquivo[] = [];
  arquivosEnviadoSessaoAtual: Anexo[] = [];
  selectedFile: Set<File>;
  documentosPendentesData: any;
  documentosPendentes = [];
  pendencias = [];
  mensagemPendencia: string;

  data: Data = new Data();
  ArquivoSelecionadoAtual: Anexo;
  qtdArquivos: number = 0;
  numDocDisponivel: number;
  indexSelecionado: number;
  dataServidor: string;

  loading: boolean = false;
  editado: boolean = false;
  mensagemInicial: boolean = true;
  listaAnexoSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  listaAnexoObservable = this.listaAnexoSubject.asObservable();
  primeiroAnexo: boolean = false;

  constructor(
    private fb: FormBuilder,
    private anexoService: AnexoService,
    private documentosService: DocumentosService,
    private validaErro: ErroService,
    private serverDate: ServerDate,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.serverDate.getDateNow().subscribe(
      (data: any) => {
        this.dataServidor = data;
      },
      (error) => this.validaErro.retornoErro(error)
    );
    this.localStorage = this.decryptLocalStorage();
    this.formAnexo = this.fb.group({
      arquivos: this.fb.array([]),
      comentario: [''],
      tipoArquivo: [''],
    });
    this.buscarTiposArquivo();
    if (this.modal) this.buscarApenasNumord();
    else this.buscarNumord();
  }
  abrirModal(data?: any) {
    this.spinner.show('modal');
    this.setarCompChave(data);
    this.buscarAnexos();
    this.buscarPendenciasCorrecao();
  }
  inicializarComponente(evento: any) {
    this.NumeroDeOrdem = evento.NumeroDeOrdem;
    this.matricula = evento.Matricula;
  }
  setarCompChave(data?: any) {
    switch (this.pagina) {
      case '103':
        // Tempo de Serviço
        this.compChave = data.DtIni.SetData + data.DtFim.SetData;
        break;
      case '007':
        // Faltas ao Trabalho
        this.compChave =
          data.DtInicio.SetData + data.DtVolta.SetData + data.CodAfa + data.CodMot + data.Seq;
        break;
      case '010':
        // Promoções
        this.compChave = data.DtComp + data.TpProc + data.Seq;
        break;
      case '013':
        // Prontuario Médico
        this.compChave = data.DtInsp.SetData;
        break;
      case '009':
        // Formação Básica e Específica
        this.compChave = data.DtTermino.SetData + data.CodCrs;
        break;
      case '018':
        // Licença Prêmio
        this.compChave = data.Seq;
        break;
      case '016':
        // Processos
        //Deferido == Situacao 3
        if (
          data.Situacao != 3 ||
          (data.Situacao == 3 && this.permissoes.find((p) => p.Descricao === 'Deferido'))
        ) {
          this.podeEditar = true;
        } else {
          this.podeEditar = false;
        }
        this.compChave = data.NumeroProcesso + data.DataRet.SetData + data.HoraRet;
        break;
      case '110':
        // Comissão
        this.compChave = data.Seq;
        break;
      case '207':
        // Experiência Profissional
        this.compChave = '00000001';
        break;
      case '050':
        // Lançamento Financeiro (RRA)
        this.compChave = data.CodigoVerba.padStart(3, '0') + data.Transacao.padStart(2, '0');
        break;
      case '012':
        // Pensão Alimentícia
        this.compChave = data.CodigoPensao;
        break;
      case '017':
        // Complemento Cadastral / Estágio
        // Complemento Cadastral / Cessão
        if (data.estagiario || data.cessao) {
          this.compChave = data.estagiario
            ? data.estagiario.NumeroOrdem.padStart(7, '0')
            : data.cessao.NumeroOrdem.padStart(7, '0');

          this.compChave +=
            data.contrato.NumeroProcesso +
            data.contrato.DataInicial.SetData +
            data.contrato.DataFinal.SetData;
        } else {
          this.compChave = data;
        }

        break;

      case '300':
        // Situação Funcional
        // compChave  dtinicio
        this.compChave = '';
        break;
      default:
        Swal('Erro', 'Tipo de página/observação não encontrado', 'error').then(() => {
          document.getElementById('btnCancelarObservacoesModal').click();
        });
        return;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.compChave;
    if (currentItem && currentItem.currentValue && changes) {
      this.listaAnexoObservable.pipe(skip(1), take(1)).subscribe((listaAnexo) => {
        if (
          listaAnexo.length > 0 &&
          this.permiteExclusao &&
          changes &&
          currentItem &&
          !this.primeiroAnexo
        ) {
          listaAnexo.forEach((item) => {
            this.excluirAnexoSelecionado(item);
          });
        }
      });
    }
    if (currentItem && currentItem.currentValue && this.matricula) {
      this.buscarNumord();
    }
    if (changes['compChave'] && changes['compChave'].currentValue !== undefined) {
      this.compChave = changes['compChave'].currentValue;
    }
    if (changes['anexoSexo'] || changes['anexoCasamento']) {
      this.anexoSexo = changes['anexoSexo'].currentValue;
      // this.anexoCarteira = changes['anexoCarteira'].currentValue;
      this.anexoCasamento = changes['anexoCasamento'].currentValue;
      this.deletarAnexosCondicionais();
    }
  }
  printColaborador(data) {
    this.matricula = data.Matricula;
  }

  buscarApenasNumord() {
    if (this.matricula) {
      this.spinner.show();
      this.anexoService.buscarNumorder(this.matricula).subscribe(
        (data: any) => {
          this.NumeroDeOrdem = data;
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.validaErro.retornoErro(error);
        }
      );
    }
  }
  buscarNumord() {
    if (this.matricula) {
      this.spinner.show();
      this.anexoService.buscarNumorder(this.matricula).subscribe(
        (data: any) => {
          this.NumeroDeOrdem = data;
          if (!this.modal) {
            if (this.pagina === '017') this.compChave = data;
          }
          this.buscarAnexos();
          // if (this.localStorage.TipoUsuario == '1') {
          //adicionar condicional de perfil aqui
          this.buscarPendenciasCorrecao();
          // } else {
          this.spinner.hide();
          // }
        },
        (error) => {
          this.spinner.hide();
          this.validaErro.retornoErro(error);
        }
      );
    }
  }

  buscarTiposArquivo() {
    this.anexoService.buscarTipoArquivo(this.pagina).subscribe(
      (data: any) => {
        if (data.length == 0) {
          Swal(
            'Documentos',
            `Nenhum tipo de arquivo encontrado para o tipo ${this.pagina}`,
            'warning'
          );
        }
        this.listaTipoArquivo = data;
      },
      (error) => this.validaErro.retornoErro(error)
    );
  }

  buscarAnexos() {
    this.loading = true;
    this.spinner.show();
    this.anexoService.buscarAnexos(this.NumeroDeOrdem, this.pagina, this.compChave).subscribe(
      (data: any) => {
        if (data && data.length > 0) {
          this.listaAnexo = this.ordernarAnexo(
            this.OrganizarExtensao(JSON.parse(JSON.stringify(data)))
          );
          this.listaAnexoSubject.next(this.listaAnexo);
          console.log('anexos recebidos', this.listaAnexo);
          this.loading = false;
        } else {
          this.listaAnexo = [];
        }
        this.spinner.hide();
        this.gatilhoAtivado.emit();
        this.listaAnexoCadastro.emit(this.listaAnexo);
        this.spinner.hide('modal');
      },
      (error) => {
        this.spinner.hide();
        this.listaAnexoCadastro.emit([]);
        this.spinner.hide('modal');

        this.validaErro.retornoErro(error);
      }
    );
  }

  editarAnexo(uploadArquivo: Anexo, index: number): void {
    this.formAnexo.get('comentario').setValue(uploadArquivo.Comentario);
    this.editado = true;
    this.ArquivoSelecionadoAtual = uploadArquivo;
    this.indexSelecionado = index;
  }

  excluirAnexo(uploadArquivo: Anexo) {
    Swal({
      title: 'Deseja excluir esse anexo?',
      text: 'Confirme para excluir anexo.',
      icon: 'info',
      dangerMode: false,
      buttons: {
        cancel: {
          text: 'Cancelar',
          value: false,
          visible: true,
        },
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
        },
      },
    }).then((willDelete) => {
      if (willDelete) {
        this.excluirAnexoSelecionado(uploadArquivo);
        if (!this.modal) {
          scrollTo(0, 0);
        }
      }
    });
  }

  excluirAnexoSelecionado(uploadArquivo: Anexo) {
    this.spinner.show('modalAnexo');
    const anexo: Anexo = this.montarDelete(uploadArquivo);
    this.anexoService.excluirAnexo(anexo).subscribe(
      (data: any) => {
        this.spinner.hide('modalAnexo');
        this.permiteExclusao ? '' : new AlertaModal('sucesso', 'Sucesso', 'Anexo Deletado!', 'P');
        this.buscarAnexos();
      },
      (error) => {
        this.spinner.hide('modalAnexo');
        this.validaErro.retornoErro(error);
      }
    );
  }

  montarDelete(uploadArquivo: Anexo): Anexo {
    uploadArquivo.Arquivos = uploadArquivo.Arquivos.map((anexo: Arquivo) => {
      if (anexo.ArquivoBase64) {
        return {
          ...anexo,
          ArquivoBase64: '0',
        };
      } else {
        return anexo;
      }
    });
    return uploadArquivo;
  }

  agruparAnexos(listaAnexo: Anexo): Anexo[] {
    listaAnexo.Arquivos = listaAnexo.Arquivos.sort((a, b) =>
      a.DataCadastro < b.DataCadastro ? 1 : a.DataCadastro == b.DataCadastro ? 0 : -1
    );
    return [listaAnexo];
  }

  ordernarAnexo(matrizAnexos: Anexo[]): Anexo[] {
    return matrizAnexos.map((upload: Anexo) => {
      upload.Arquivos = upload.Arquivos.sort((a: Arquivo, b: Arquivo) => {
        if (a.Pagina > b.Pagina) return 1;
        else if (b.Pagina > a.Pagina) return -1;
        else if (a.Pagina == b.Pagina) return 0;
      });
      return upload;
    });
  }

  // formatarDataAnexo(listaAnexo: Anexo[]): Anexo[] {
  //   listaAnexo = listaAnexo.map((anexo: Anexo) => { // adicionar a string informando que é um jpeg na base 64
  //     anexo.Arquivos.map((arquivo: Arquivo) => {
  //     const dia = arquivo.DataCadastro.substr(8, 2);
  //     const mes = this.meses[Number(arquivo.DataCadastro.substr(5, 2)) - 1]
  //     const ano = arquivo.DataCadastro.substr(0, 4)
  //     const hora = arquivo.DataCadastro.substr(11, 5)

  //     return { ...arquivo, DataCadastro: `${dia} de ${mes} de ${ano} às ${hora}` }
  //     })
  //     return anexo
  //   });

  //   return listaAnexo;
  // }

  formatarArquivoAnexo(listaUpload: Anexo[]): Anexo[] {
    listaUpload = listaUpload.map((upload: Anexo) => {
      // adicionar a string informando que é um jpeg na base 64
      upload.Arquivos = upload.Arquivos.map((anexo: Arquivo) => {
        if (anexo.ArquivoBase64) {
          return {
            ...anexo,
            ArquivoBase64:
              anexo.Extensao == 'jpeg'
                ? 'data:image/jpeg;base64,' + anexo.ArquivoBase64
                : 'data:application/pdf;base64,' + anexo.ArquivoBase64,
          };
        } else {
          return anexo;
        }
      });
      return upload;
    });
    return listaUpload;
  }

  OrganizarExtensao(listaUpload: Anexo[]): Anexo[] {
    return listaUpload.map((upload: Anexo) => {
      // adicionar a string informando que é um jpeg na base 64
      upload.Arquivos = upload.Arquivos.map((anexo: Arquivo) => {
        if (anexo.ArquivoBase64) {
          return {
            ...anexo,
            Extensao: anexo.Extensao == '.jpeg' ? 'jpeg' : 'pdf',
          };
        } else {
          return anexo;
        }
      });
      return upload;
    });
  }

  onArquivoChange(evento): void {
    this.arquivos.reset();
    const { files } = evento.target;
    this.qtdArquivos = files.length;
    if (files.length > 0) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.arquivos.push(this.fb.control(e.target.result));
        };
        reader.readAsDataURL(file);
      }
      this.formAnexo.get('tipoArquivo').setValidators([Validators.required]);
    } else {
      this.arquivos.clear();
      this.formAnexo.get('tipoArquivo').setValidators(null);
    }
    this.formAnexo.get('tipoArquivo').updateValueAndValidity();
  }

  limpar(evento) {
    evento.target.value = null;
    this.limparCampos();
  }

  get arquivos(): FormArray {
    return this.formAnexo.get('arquivos') as FormArray;
  }

  limparCampos(): void {
    this.formAnexo.reset({
      arquivos: [],
      comentario: '',
      tipoArquivo: '',
    });
    this.arquivos.clear();
    this.qtdArquivos = 0;
    this.formAnexo.get('tipoArquivo').setValidators(null);
    this.formAnexo.get('tipoArquivo').updateValueAndValidity();
    this.editado = false;
  }

  submitAnexo(anexoForm: any): void {
    // if (!this.enviarSeparadamente) {
    //   console.log('submitAnexo', !this.enviarSeparadamente)
    //   this.editado ? this.alterarAnexo() : this.salvarAnexo();
    // } else {
    //   console.log('submitAnexo 2', !this.enviarSeparadamente)
    //   this.adicionarAnexo();
    // }
    this.salvarAnexo();
  }

  salvarAnexo(): void {
    this.adicionarAnexo();
    if (!this.exportarParaObjeto) {
      this.anexoService.salvarAnexo(this.listaAnexo).subscribe(
        (data) => {
          this.primeiroAnexo = true;
          this.limparCampos();
          this.buscarAnexos();
        },
        (error) => this.validaErro.retornoErro(error)
      );
    }
  }

  alterarAnexo(): void {
    const comentario = this.formAnexo.get('comentario').value;
    if (!this.ArquivoSelecionadoAtual.ArquivoSalvo) {
      this.listaAnexo[this.indexSelecionado].Comentario = comentario;
      this.ArquivoSelecionadoAtual = null;
      this.editado = false;
    } else {
      this.ArquivoSelecionadoAtual.Comentario = comentario;
      this.anexoService.AlterarAnexo(this.ArquivoSelecionadoAtual).subscribe(
        (data) => {
          this.buscarAnexos();
          this.editado = false;
          const pend = this.documentosPendentes.find(
            (item) => item.Descricao == this.ArquivoSelecionadoAtual.Arquivos[0].DescricaoTRG
          );
          if (this.documentosPendentesData.documentosAnexados.hasOwnProperty(pend.Label)) {
            this.documentosPendentesData.documentosAnexados[pend.Label] = 0;
          }
          this.ArquivoSelecionadoAtual = null;
        },
        (error) => {
          this.validaErro.retornoErro(error);
          this.editado = false;
        }
      );
    }
    this.limparCampos();
    this.inputAdicionar.nativeElement.value = '';
  }

  adicionarAnexo(): void {
    let uploadArquivos = this.montaArquivos();
    const itemComTipoIgual = this.listaAnexo.find(
      (item) => item.Arquivos[0].Tipo == uploadArquivos.Arquivos[0].Tipo
    );
    if (itemComTipoIgual) {
      Swal({
        title: 'Já existe um anexo com esse tipo de arquivo. Deseja excluir o anexo antigo?',
        text: 'Confirme para excluir anexo.',
        icon: 'info',
        dangerMode: false,
        buttons: {
          cancel: {
            text: 'Cancelar',
            value: false,
            visible: true,
          },
          confirm: {
            text: 'Ok',
            value: true,
            visible: true,
          },
        },
      }).then((willDelete) => {
        const permissaoExcluir = this.documentosPendentes.filter(
          (item) =>
            item.Descricao === itemComTipoIgual.Arquivos[0].DescricaoTRG && item.Pendente === 1
        );
        if (willDelete && permissaoExcluir.length > 0) {
          this.excluirAnexoSelecionado(itemComTipoIgual);
        } else if (this.localStorage.TipoUsuario == '1') {
          Swal(
            'Atenção',
            'Você não tem permissão de excluir esse anexo. Caso deseje alguma mudança, solicite ao RH responsável.',
            'warning'
          );
        }
      });
    } else {
      this.arquivosEnviadoSessaoAtual.push(uploadArquivos);
      //MOVER PARA CÁ
      for (const documento in this.documentosPendentes) {
        if (
          this.documentosPendentes[documento].Pendente == 1 &&
          this.documentosPendentes[documento].Descricao == uploadArquivos.Arquivos[0].DescricaoTRG
        ) {
          const itemIgual =
            this.documentosPendentes[documento].Descricao ==
            uploadArquivos.Arquivos[0].DescricaoTRG;

          if (
            this.documentosPendentesData.documentosAnexados.hasOwnProperty(
              this.documentosPendentes[documento].Label
            )
          ) {
            this.documentosPendentesData.documentosAnexados[
              this.documentosPendentes[documento].Label
            ] = 0;
          }
        }
      }
      uploadArquivos.Arquivos = uploadArquivos.Arquivos.map((anexo: Arquivo) => {
        anexo.DataCadastro = new Data(this.dataServidor);
        anexo.Nome = this.localStorage.NomeUsuario;
        return anexo;
      });
      let anexo = this.agruparAnexos(uploadArquivos);
      anexo = this.formatarArquivoAnexo(anexo);
      this.listaAnexo.push(...anexo);
      this.limparCampos();
    }
    this.inputAdicionar.nativeElement.value = '';
  }

  retornarValores(): Anexo[] {
    return this.listaAnexo;
  }

  montaArquivos(): Anexo {
    let listaArquivos = this.RemoverNulos();
    const { comentario, tipoArquivo } = this.formAnexo.value;
    const tipoArquivoInfo = this.listaTipoArquivo.find((item) => item.Codigo == tipoArquivo);
    this.ListaArquivos = this.RemoverNulos();
    if (listaArquivos.length > 0) {
      let numordValido = this.buscarUltimoNumord() + 1;
      listaArquivos = listaArquivos.map((arquivo, index) => {
        return {
          IdUsuario: this.localStorage.CodigoUsuario,
          ArquivoBase64: arquivo.split(',')[1],
          // revisar com back
          NomeUsuario: this.localStorage.NomeUsuario,
          Nome: this.localStorage.NomeUsuario,
          Matricula: this.matricula,
          Empfil: this.localStorage.FilialAtual.toString(),
          Grupo: tipoArquivo.substr(0, 3),
          Tipo: tipoArquivo.substr(3, 3),
          Codigo: tipoArquivo,
          Extensao: arquivo.split(',')[0].includes('image') ? 'jpeg' : 'pdf',
          NumDoc: numordValido.toString().padStart(3, '0'),
          Numord: this.NumeroDeOrdem?.replace(/^0/, ''),
          Pagina: listaArquivos.length == 1 ? '1' : (index + 1).toString(),
          CompChave: this.compChave,
          DescricaoTRG: tipoArquivoInfo.Descricao,
        };
      });
    } else {
      const anexo = {
        NomeUsuario: this.localStorage.NomeUsuario,
        Comentario: comentario,
        Arquivos: [
          {
            IdUsuario: this.localStorage.CodigoUsuario,
            NomeUsuario: this.localStorage.NomeUsuario,
            Nome: this.localStorage.NomeUsuario,
            ArquivoBase64: null,
            Matricula: this.matricula,
            Empfil: this.localStorage.FilialAtual.toString(),
            Grupo: this.pagina,
            Tipo: '777',
            Codigo: `${this.pagina}000`,
            Extensao: null,
            NumDoc: (this.buscarUltimoNumord() + 1).toString().padStart(3, '0'),
            Numord: this.NumeroDeOrdem.replace(/^0/, ''),
            CompChave: this.compChave,
            Pagina: '',
            DescricaoTRG: tipoArquivoInfo.Descricao,
          },
        ],
        ArquivoSalvo: false,
      };
      this.numDocDisponivel += 1;
      return anexo;
    }
    return {
      NomeUsuario: this.localStorage.NomeUsuario,
      Comentario: comentario,
      Arquivos: listaArquivos,
      ArquivoSalvo: false,
    };
  }

  RemoverNulos() {
    let lista = this.arquivos.value;
    lista = lista.filter((lista) => {
      if (lista) return lista;
    });
    return lista;
  }

  buscarUltimoNumord(): number {
    return Number('00' + this.listaAnexo.length);
  }

  downloadAnexo(anexo: Arquivo) {
    this.spinner.show('modalAnexo');
    this.anexoService.obterAnexoBase64(anexo).subscribe({
      next: (data: string) => {
        const pdf = `data:application/pdf;base64,${data}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = pdf;
        downloadLink.download = this.buscarNomeArquivo(anexo);
        downloadLink.click();
        this.spinner.hide('modalAnexo');
      },
      error: (err) => {
        this.spinner.hide('modalAnexo');
        Swal('Error', `${err.error.Mensagem}`, 'warning');
      },
    });
  }

  buscarNomeArquivo(anexo: Arquivo) {
    let listaAux = [...this.listaTipoArquivo];
    const extensaoArquivo = anexo.Extensao.toLowerCase() == 'pdf' ? '.pdf' : '.jpeg';
    let nomeArquivo = null;
    nomeArquivo = listaAux.find((tipo) => {
      if (tipo && tipo.Codigo === anexo.Codigo) {
        return tipo.Descricao;
      }
    })?.Descricao;
    return nomeArquivo + extensaoArquivo;
  }

  buscarPendenciasCorrecao() {
    this.anexoService.buscarPendencias(this.NumeroDeOrdem).subscribe((data: any) => {
      const mensagemAnexos = data.descricaoEmail != null ? data.descricaoEmail : '';
      (this.documentosPendentesData = {
        documentosAnexados: {
          nomeacaoAnexo: data.documentosAnexados.nomeacaoAnexo,
          fotoAnexo: data.documentosAnexados.fotoAnexo,
          identidadeAnexo: data.documentosAnexados.identidadeAnexo,
          carteiraProfAnexo: data.documentosAnexados.carteiraProfAnexo,
          cpfAnexo: data.documentosAnexados.cpfAnexo,
          pisPasepAnexo: data.documentosAnexados.pisPasepAnexo,
          certificadoMilitarAnexo: data.documentosAnexados.certificadoMilitarAnexo,
          carteiraDeMotoristaAnexo: data.documentosAnexados.carteiraDeMotoristaAnexo,
          certidaoDeNascimentoAnexo: data.documentosAnexados.certidaoDeNascimentoAnexo,
          certidaoDeCasamentoAnexo: data.documentosAnexados.certidaoDeCasamentoAnexo,
          comprovanteDeResidenciaAnexo: data.documentosAnexados.comprovanteDeResidenciaAnexo,
        },
        descricaoEmail: mensagemAnexos,
      }),
        (this.documentosPendentes = [
          {
            Descricao: 'NOMEACAO',
            Pendente: this.documentosPendentesData.documentosAnexados.nomeacaoAnexo,
            Texto: 'Nomeação',
            Label: 'nomeacaoAnexo',
          },
          {
            Descricao: 'FOTO',
            Pendente: this.documentosPendentesData.documentosAnexados.fotoAnexo,
            Texto: 'Foto',
            Label: 'fotoAnexo',
          },
          {
            Descricao: 'IDENTIDADE',
            Pendente: this.documentosPendentesData.documentosAnexados.identidadeAnexo,
            Texto: 'Identidade',
            Label: 'identidadeAnexo',
          },
          {
            Descricao: 'CARTEIRA PROFISSIONAL',
            Pendente: this.documentosPendentesData.documentosAnexados.carteiraProfAnexo,
            Texto: 'Carteira Profissional',
            Label: 'carteiraProfAnexo',
          },
          {
            Descricao: 'CPF',
            Pendente: this.documentosPendentesData.documentosAnexados.cpfAnexo,
            Texto: 'CPF',
            Label: 'cpfAnexo',
          },
          {
            Descricao: 'PIS/PASEP',
            Pendente: this.documentosPendentesData.documentosAnexados.pisPasepAnexo,
            Texto: 'PIS/Pasep',
            Label: 'pisPasepAnexo',
          },
          {
            Descricao: 'CERTIFICADO MILITAR',
            Pendente: this.documentosPendentesData.documentosAnexados.certificadoMilitarAnexo,
            Texto: 'Certificado Militar',
            Label: 'certificadoMilitarAnexo',
          },
          {
            Descricao: 'CARTEIRA DE MOTORISTA',
            Pendente: this.documentosPendentesData.documentosAnexados.carteiraDeMotoristaAnexo,
            Texto: 'Carteira de Motorista',
            Label: 'carteiraDeMotoristaAnexo',
          },
          {
            Descricao: 'CERTIDAO DE NASCIMENTO',
            Pendente: this.documentosPendentesData.documentosAnexados.certidaoDeNascimentoAnexo,
            Texto: 'Certidão de Nascimento',
            Label: 'certidaoDeNascimentoAnexo',
          },
          {
            Descricao: 'CERTIDAO DE CASAMENTO',
            Pendente: this.documentosPendentesData.documentosAnexados.certidaoDeCasamentoAnexo,
            Texto: 'Certidão de Casamento',
            Label: 'certidaoDeCasamentoAnexo',
          },
          {
            Descricao: 'COMPROVANTE DE RESIDENCIA',
            Pendente: this.documentosPendentesData.documentosAnexados.comprovanteDeResidenciaAnexo,
            Texto: 'Comprovante de Residência',
            Label: 'comprovanteDeResidenciaAnexo',
          },
        ]);
      this.deletarAnexosCondicionais();
      // Itera sobre o objeto para encontrar as pendências
      this.pendencias = [];
      for (const documento in this.documentosPendentes) {
        if (
          this.documentosPendentes.hasOwnProperty(documento) &&
          this.documentosPendentes[documento].Pendente === 1
        ) {
          this.pendencias.push(this.documentosPendentes[documento].Texto);
          // Cria a mensagem personalizada
          this.mensagemPendencia = `Existe(m) ${
            this.pendencias.length
          } anexo(s) pendente(s): ${this.pendencias.join(
            this.pendencias.length > 2 ? ', ' : ' e '
          )}. A mensagem fornecida pelo RH foi: "${mensagemAnexos}".`;
        } else {
          this.spinner.hide();
        }
      }
      const mensagemAviso = ` Para modificar seus anexos, clique na aba 'Documentos' e vá até a seção 'Anexos', você terá acesso aos documentos anexados clicando no ícone de folha.`;

      if (
        this.localStorage.TipoUsuario == '1' &&
        this.perfil == true &&
        this.pendencias.length > 0
      ) {
        Swal('Atenção', this.mensagemPendencia + mensagemAviso, 'warning');
        this.mensagemInicial = false;
      }
    });
  }

  deletarAnexosCondicionais() {
    if (this.anexoSexo != 1) {
      const deletarItem = this.documentosPendentes.findIndex(
        (item) => item.Descricao == 'CERTIFICADO MILITAR'
      );

      if (deletarItem !== -1) {
        this.documentosPendentes.splice(deletarItem, 1);
      }

      const deletarItemSelect = this.listaTipoArquivo.findIndex(
        (item) => item.Descricao == 'CERTIFICADO MILITAR'
      );

      if (deletarItemSelect !== -1) {
        this.listaTipoArquivo.splice(deletarItemSelect, 1);
      }
    }

    if (this.anexoCasamento == 0) {
      const deletarItem = this.documentosPendentes.findIndex(
        (item) => item.Descricao == 'CERTIDAO DE CASAMENTO'
      );

      if (deletarItem !== -1) {
        this.documentosPendentes.splice(deletarItem, 1);
      }

      const deletarItemSelect = this.listaTipoArquivo.findIndex(
        (item) => item.Descricao == 'CERTIDAO DE CASAMENTO'
      );

      if (deletarItemSelect !== -1) {
        this.listaTipoArquivo.splice(deletarItemSelect, 1);
      }
    }

    if (this.anexoCarteira == 0) {
      const deletarItem = this.documentosPendentes.findIndex(
        (item) => item.Descricao == 'CARTEIRA DE MOTORISTA'
      );

      if (deletarItem !== -1) {
        this.documentosPendentes.splice(deletarItem, 1);
      }

      const deletarItemSelect = this.listaTipoArquivo.findIndex(
        (item) => item.Descricao == 'CARTEIRA DE MOTORISTA'
      );

      if (deletarItemSelect !== -1) {
        this.listaTipoArquivo.splice(deletarItemSelect, 1);
      }
    }
    //CONTINUAR DELETAR CONDICIONAIS NAS LISTAS//
    //emitir lista completa para docs > profile
    this.listaPendencias.emit(this.documentosPendentes);
  }

  permiteMudanca(descricao?: string): boolean {
    var itensEncontrados;
    if (descricao) {
      itensEncontrados = this.documentosPendentes.filter(
        (item) => item.Descricao === descricao && item.Pendente === 1
      );
    } else {
      itensEncontrados = this.documentosPendentes.filter((item) => item.Pendente === 1);
    }
    if (itensEncontrados.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  concluirModificacoes() {
    //adicionar condicional de perfil
    if (
      this.formAnexo.value.arquivos.length > 0 &&
      (this.formAnexo.get('comentario').value == '' ||
        this.formAnexo.get('tipoArquivo').value == '')
    ) {
      Swal({
        title: 'Atenção',
        text: 'Confirme para excluir anexo.Selecione o tipo de arquivo, insira um comentário e adicione o anexo para concluir a operação corretamente ou clique em cancelar para desistir da inclusão.',
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Cancelar',
            value: false,
            visible: true,
          },
          confirm: {
            text: 'Ok',
            value: true,
            visible: true,
          },
        },
      }).then((result) => {
        if (!result) {
          this.limparCampos();
          this.concluirModificacoes();
        }
      });
    } else {
      let botao = document.getElementById('botaoAnexoFechar');
      botao.click();
      let permissaoUpar = false;
      let docsMensagem = [];
      const itensPendencias = this.documentosPendentes.filter((item) => item.Pendente === 1);
      for (let pendencia in itensPendencias) {
        const pendenciaAtt =
          this.documentosPendentesData.documentosAnexados[itensPendencias[pendencia].Label];
        if (pendenciaAtt == 0) {
          const itemExisteNaLista = this.listaAnexo.filter(
            (item) => item.Arquivos[0].DescricaoTRG == itensPendencias[pendencia].Descricao
          );
          if (itemExisteNaLista.length > 0) {
            permissaoUpar = true;
          } else {
            permissaoUpar = false;
            docsMensagem.push(itensPendencias[pendencia].Texto);
          }
        }
      }

      if (permissaoUpar) {
        const EmailDestinatario = this.emailColaborador.toLowerCase();
        const CorrecoesSolicitadas = this.documentosPendentesData;
        const numeroDeOrdem = this.NumeroDeOrdem;
        this.documentosService
          .salvarAnexosMarcados({
            EmailDestinatario,
            CorrecoesSolicitadas,
            numeroDeOrdem,
          })
          .subscribe(
            (data) => {
              this.buscarPendenciasCorrecao();
            },
            (error) => {
              new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
              this.spinner.hide();
            }
          );
      } else {
        if (docsMensagem.length > 0) {
          let mensagem = `Você alterou ou fez o upload de um ou mais anexos pendentes, mas a lista de anexos atual não corresponde ao esperado. Por favor, insira o anexo correspondente para que suas pendências sejam atualizadas.
        Documento(s) pendente(s): ${docsMensagem.join(docsMensagem.length > 2 ? ', ' : ' e ')}.`;
          Swal('Atenção', mensagem, 'warning');
        }
      }
    }
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}

export interface Anexo {
  NomeUsuario: string;
  Comentario: string;
  ArquivoSalvo: boolean;
  Arquivos: Arquivo[];
}

export interface Arquivo {
  ArquivoBase64: string;
  Codigo: string;
  Empfil: string;
  Extensao: string;
  Grupo: string;
  Matricula: string;
  IdUsuario: string;
  NomeUsuario: string;
  NumDoc: string;
  Tipo: string;
  Nome?: string;
  FotoPerfil?: string;
  FotoPerfilBytes?: string;
  DataCadastro?: Data;
  Numord: string;
  CompChave: string;
  Pagina: string;
  DescricaoTRG: string;
}
