import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { ErroService } from 'src/app/service/erros/erro.service';
import { dataValidator } from 'src/app/service/Validators/dataValidator';
import Swal from 'sweetalert';
import { CadastroEstagioService } from './cadastro-estagio.service';
import { Parametro } from 'src/app/service/interfaces/Parametro';
import { Data } from 'src/app/service/interfaces/Data';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { AlertaModal } from 'src/app/componentes/componentes-funcionais/alerta-modal/alerta-modal.component';
import { Nivel } from 'src/app/service/interfaces/Nivel';
import { AgenteIntegracao } from 'src/app/service/interfaces/AgenteIntegracao';
import { InstituicaoEnsino } from 'src/app/service/interfaces/InstituicaoEnsino';
import { ContratosEstagio } from 'src/app/service/interfaces/ContratosEstagio';
import { Supervisor } from 'src/app/service/interfaces/Supervisor';
import { validarDataMaiorOuIgual } from '../../processos/data-validator';
import { ComplementoCadastralService } from '../complemento-cadastral.service';

@Component({
  selector: 'app-cadastro-estagio',
  templateUrl: './cadastro-estagio.component.html',
  styleUrls: ['./cadastro-estagio.component.css'],
})
export class CadastroEstagioComponent implements OnInit {
  localStorage: LocalStorage;
  navigation: any;
  temErro: boolean = false;
  numeroOrdem: string;
  matricula: string;
  editar: boolean = false;
  visualizacao: boolean = false;
  nivel: Nivel;
  formEstagio: FormGroup;
  listaNaturezaEstagio: Parametro[];
  listaTipoLogradouro: Parametro[];
  listaNivelEstagio: Parametro[];
  listaAgenteIntegracao: AgenteIntegracao[];
  listaInstituicaoEnsino: InstituicaoEnsino[];
  listaFuncoesEstagio: Parametro[];

  constructor(
    public fb: FormBuilder,
    private cadastroEstagioService: CadastroEstagioService,
    private complementoCadastralService: ComplementoCadastralService,
    private route: Router,
    private validaErro: ErroService,
    private spinner: NgxSpinnerService
  ) {}
  ngOnInit() {
    const navigation = this.route.getCurrentNavigation();
    if (navigation) {
      console.log('navigation cadastro estagio', navigation);
    }
    this.localStorage = this.decryptLocalStorage();
    this.formEstagio = this.fb.group({
      numeroProcesso: ['', Validators.required],
      numeroProcessoAnterior: [''],
      natureza: ['', Validators.required],
      nivel: ['', Validators.required],
      areaAtuacao: ['', Validators.required],
      numApolice: ['', Validators.required],
      funcao: ['', Validators.required],
      valorBolsa: [{ value: '', disabled: true }, Validators.required],
      dataPrevisaoTermino: ['', [Validators.required]],
      dataInicial: ['', [Validators.required, validarDataMaiorOuIgual()]],
      dataFinal: ['', [Validators.required]],
      instEnsinoCnpj: ['', Validators.required],
      instEnsinoID: ['', Validators.required],
      instEnsinoRazaoSocial: ['', Validators.required],
      instEnsinoCep: ['', Validators.required],
      instEnsinoLogradouro: ['', Validators.required],
      instEnsinoTipoLogradouro: [''],
      instEnsinoNumero: ['', Validators.required],
      instEnsinoMunicipio: ['', Validators.required],
      instEnsinoBairro: ['', Validators.required],
      instEnsinoUf: ['', Validators.required],
      agenteIntegracaoCnpj: ['', Validators.required],
      agenteIntegracaoID: ['', Validators.required],
      agenteIntegracaoRazaoSocial: ['', Validators.required],
      agenteIntegracaoCep: ['', Validators.required],
      agenteIntegracaoLogradouro: ['', Validators.required],
      agenteIntegracaoTipoLogradouro: [''],
      agenteIntegracaoNumero: ['', Validators.required],
      agenteIntegracaoMunicipio: ['', Validators.required],
      agenteIntegracaoBairro: ['', Validators.required],
      agenteIntegracaoUf: ['', Validators.required],
      supervisorMatricula: ['', Validators.required],
      supervisorEmpfil: ['', Validators.required],
      supervisorNome: ['', Validators.required],
      supervisorNumOrd: ['', Validators.required],
    });
    this.buscarParametro();
  }

  carregarValorBolsa(nivel: Nivel) {
    this.nivel = nivel;
    console.log(this.nivel);
    this.formEstagio.get('valorBolsa').setValue(nivel.Valor);
  }

  carregarEstagio(estagio: ContratosEstagio): void {
    this.formEstagio.get('instEnsinoCnpj').setValue(estagio.InstituicaoEnsino.CNPJ);
    this.formEstagio.get('agenteIntegracaoCnpj').setValue(estagio.AgenteIntegracao.CNPJ);
    this.formEstagio.get('numeroProcesso').setValue(estagio.NumeroProcesso);
    this.formEstagio.get('numeroProcessoAnterior').setValue(estagio.NumeroProcessoAnterior);
    this.formEstagio.get('natureza').setValue(estagio.NaturezaEstagio);
    this.formEstagio.get('nivel').setValue(estagio.NivelEstagio);
    this.formEstagio.get('numApolice').setValue(estagio.NumeroApolice);

    this.formEstagio.get('areaAtuacao').setValue(estagio.AreaAtuacao);
    this.formEstagio.get('funcao').setValue(estagio.CodigoEstagio);
    this.formEstagio.get('valorBolsa').setValue(estagio.ValorBolsa.Valor);
    this.formEstagio
      .get('dataPrevisaoTermino')
      .setValue(this.corrigeDatas(estagio.DataTermino.ToDate));
    if (this.editar) {
      this.formEstagio.get('dataInicial').setValue(this.corrigeDatas(estagio.DataInicial.ToDate));
      this.formEstagio.get('dataInicial').clearValidators();
      this.formEstagio.get('dataInicial').updateValueAndValidity();
    }
    this.editar
      ? this.formEstagio.get('dataFinal').setValue(this.corrigeDatas(estagio.DataFinal.ToDate))
      : '';
    this.formEstagio.get('supervisorEmpfil').setValue(estagio.Supervisor.EmpresaFilial);
    this.formEstagio.get('supervisorMatricula').setValue(estagio.Supervisor.Matricula);
    this.formEstagio.get('supervisorNome').setValue(estagio.Supervisor.Nome);
    this.formEstagio.get('supervisorNumOrd').setValue(estagio.Supervisor.NumeroDeOrdem);
    this.nivel = estagio.ValorBolsa;
    this.carregarInstEnsino();
    this.carregarAgenteIntegracao();
  }

  buscarSupervisor(): void {
    //const matricula = this.formEstagio.get('supervisorMatricula').value;
    if (this.formEstagio.get('supervisorMatricula').value === '') {
      new AlertaModal('alerta', 'Atenção!', 'Matrícula do Supervisor inválida.', 'p');
    } else {
      this.cadastroEstagioService
        .buscarSupervisor(
          this.formEstagio.get('supervisorMatricula').value,
          this.localStorage.FilialAtual.toString()
        )
        .subscribe(
          (data: Supervisor) => {
            this.formEstagio.get('supervisorEmpfil').setValue(data.EmpresaFilial);
            //this.formEstagio.get('supervisorMatricula').setValue(data.Matricula);
            this.formEstagio.get('supervisorNome').setValue(data.Nome);
            this.formEstagio.get('supervisorNumOrd').setValue(data.NumeroDeOrdem);
          },
          (error) => this.validaErro.retornoErro(error)
        );
    }
  }

  buscarParametro(): void {
    this.spinner.show();
    const empresaFilial = this.localStorage.FilialAtual.toString();
    this.cadastroEstagioService.buscarParametros(empresaFilial).subscribe(
      (data: any) => {
        this.listaNaturezaEstagio = data.NaturezasEstagio;
        this.listaNivelEstagio = data.NiveisEstagio;
        this.listaAgenteIntegracao = data.AgenteIntegracao;
        this.listaInstituicaoEnsino = data.InstituicaoEnsino;
        this.listaTipoLogradouro = data.TiposLogradouro;
        this.listaFuncoesEstagio = data.FuncoesEstagio;
        if (history.state.data) {
          console.log('navigation', history.state.data);
          this.navigation = history.state.data;
          this.numeroOrdem = history.state.data.numeroOrdem;
          this.matricula = history.state.data.matricula;
          console.log(this.numeroOrdem);

          this.editar = history.state.data.editar;
          if (history.state.data.visualizacao) {
            this.visualizacao = true;
            this.formEstagio.disable();
          }
          !history.state.data.NovoCadastro
            ? this.carregarEstagio(history.state.data.estagio)
            : this.carregarValorBolsa(history.state.data.estagio.Nivel);
        } else {
          this.route.navigate(['/complemento-cadastral/estagio']);
        }
        this.spinner.hide();
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      }
    );
  }

  verificaData(dataInicial: string, dataFinal: string): void {
    let formDataInicial = this.formEstagio.value[dataInicial];
    let formDataFinal = this.formEstagio.value[dataFinal];

    if (formDataInicial.length === 8 && formDataFinal.length === 8) {
      formDataInicial =
        formDataInicial.substr(4, 4) + formDataInicial.substr(2, 2) + formDataInicial.substr(0, 2);
      formDataFinal =
        formDataFinal.substr(4, 4) + formDataFinal.substr(2, 2) + formDataFinal.substr(0, 2);
      if (Number(formDataInicial) < Number(formDataFinal)) return;

      new AlertaModal('alerta', 'Atenção!', 'Data final deve ser posterior a data inicial', 'p');
      this.formEstagio.get(dataInicial).setValue('');
      this.formEstagio.get(dataFinal).setValue('');
    }
  }

  setarValorBolsa() {
    console.log('o código do valor é ', this.formEstagio.get('funcao').value);
    let funcaoEstagio = this.listaFuncoesEstagio.find(
      (funcao) => funcao.Codigo == this.formEstagio.get('funcao').value
    );
    console.log('funcao estagio', funcaoEstagio);
    if (funcaoEstagio) {
      this.formEstagio.get('valorBolsa').setValue(funcaoEstagio.Valor);
      console.log('valor da bolsa', this.formEstagio.get('valorBolsa').value);
    } else {
      this.formEstagio.get('valorBolsa').setValue('');
    }
  }

  carregarInstEnsino(): void {
    const cnpj = this.formEstagio.get('instEnsinoCnpj').value;
    const intstEnsino = this.listaInstituicaoEnsino.find((inst) => inst.CNPJ === cnpj);
    this.formEstagio.get('instEnsinoID').setValue(intstEnsino.Id);
    this.formEstagio.get('instEnsinoRazaoSocial').setValue(intstEnsino.NomeRazao);
    this.formEstagio.get('instEnsinoCep').setValue(intstEnsino.Cep);
    this.formEstagio.get('instEnsinoLogradouro').setValue(intstEnsino.Logradouro);
    this.formEstagio.get('instEnsinoTipoLogradouro').setValue(intstEnsino.TipoLogradouro);
    this.formEstagio.get('instEnsinoNumero').setValue(intstEnsino.Numero);
    this.formEstagio.get('instEnsinoMunicipio').setValue(intstEnsino.Cidade);
    this.formEstagio.get('instEnsinoBairro').setValue(intstEnsino.Bairro);
    this.formEstagio.get('instEnsinoUf').setValue(intstEnsino.UF);
  }

  carregarAgenteIntegracao(): void {
    const cnpj = this.formEstagio.get('agenteIntegracaoCnpj').value;
    const agenteIntegracao = this.listaAgenteIntegracao.find((agente) => agente.CNPJ === cnpj);
    this.formEstagio.get('agenteIntegracaoID').setValue(agenteIntegracao.Id);
    this.formEstagio.get('agenteIntegracaoRazaoSocial').setValue(agenteIntegracao.NomeRazao);
    this.formEstagio.get('agenteIntegracaoCep').setValue(agenteIntegracao.Cep);
    this.formEstagio.get('agenteIntegracaoLogradouro').setValue(agenteIntegracao.Logradouro);
    this.formEstagio
      .get('agenteIntegracaoTipoLogradouro')
      .setValue(agenteIntegracao.TipoLogradouro);
    this.formEstagio.get('agenteIntegracaoNumero').setValue(agenteIntegracao.Numero);
    this.formEstagio.get('agenteIntegracaoMunicipio').setValue(agenteIntegracao.Cidade);
    this.formEstagio.get('agenteIntegracaoBairro').setValue(agenteIntegracao.Bairro);
    this.formEstagio.get('agenteIntegracaoUf').setValue(agenteIntegracao.UF);
  }

  submitEstagio(): void {
    if (this.formEstagio.invalid) {
      new AlertaModal('alerta', 'Atenção!', 'Preencha todos os campos corretamente.', 'p');
      this.temErro = true;
    } else {
      const estagio = this.montarEstagio();
      this.editar ? this.editarEstagio(estagio) : this.salvarEstagio(estagio);
    }
    const estagio = this.montarEstagio();
    console.log(estagio);
  }

  editarEstagio(estagio: ContratosEstagio): void {
    this.spinner.show();
    estagio.DataTermino.SetData = estagio.DataTermino.ToDate;
    estagio.DataInicial.SetData = estagio.DataInicial.ToDate;
    estagio.DataFinal.SetData = estagio.DataFinal.ToDate;
    this.cadastroEstagioService.editarEstagio(estagio, this.numeroOrdem).subscribe(
      async (data) => {
        this.spinner.hide();
        new AlertaModal('sucesso', 'Sucesso!', 'O estágio foi editado', 'p');
        this.route.navigate(['/complemento-cadastral/estagio']);
      },
      (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  salvarEstagio(estagio: ContratosEstagio): void {
    this.spinner.show();
    estagio.DataTermino.SetData = estagio.DataTermino.ToDate;
    estagio.DataInicial.SetData = estagio.DataInicial.ToDate;
    estagio.DataFinal.SetData = estagio.DataFinal.ToDate;
    this.cadastroEstagioService.salvarEstagio(estagio, this.numeroOrdem).subscribe(
      async (data) => {
        this.spinner.hide();
        new AlertaModal('sucesso', 'Sucesso!', 'O estágio foi inserido', 'p');
        this.route.navigate(['/complemento-cadastral/estagio']);
      },
      (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  montarEstagio(): ContratosEstagio {
    return {
      NumeroProcesso: this.formEstagio.get('numeroProcesso').value,
      NumeroProcessoAnterior: this.formEstagio.get('numeroProcessoAnterior').value,
      NaturezaEstagio: this.formEstagio.get('natureza').value,
      NivelEstagio: this.formEstagio.get('nivel').value,
      AreaAtuacao: this.formEstagio.get('areaAtuacao').value,
      NumeroApolice: this.formEstagio.get('numApolice').value,
      ValorBolsa: this.formEstagio.get('valorBolsa').value,
      CodigoEstagio: this.formEstagio.get('funcao').value,
      DataTermino: new Data(this.converteDatas(this.formEstagio.get('dataPrevisaoTermino').value)),
      DataInicial: new Data(this.converteDatas(this.formEstagio.get('dataInicial').value)),
      DataFinal: new Data(this.converteDatas(this.formEstagio.get('dataFinal').value)),
      Supervisor: {
        EmpresaFilial: this.formEstagio.get('supervisorEmpfil').value,
        Matricula: this.formEstagio.get('supervisorMatricula').value,
        Nome: this.formEstagio.get('supervisorNome').value,
        NumeroDeOrdem: this.formEstagio.get('supervisorNumOrd').value,
      },
      InstituicaoEnsino: {
        Bairro: this.formEstagio.get('instEnsinoBairro').value,
        CNPJ: this.formEstagio.get('instEnsinoCnpj').value,
        Cep: this.formEstagio.get('instEnsinoCep').value,
        Cidade: this.formEstagio.get('instEnsinoMunicipio').value,
        Id: this.formEstagio.get('instEnsinoID').value,
        Logradouro: this.formEstagio.get('instEnsinoLogradouro').value,
        NomeRazao: this.formEstagio.get('instEnsinoRazaoSocial').value,
        Numero: Number(this.formEstagio.get('instEnsinoNumero').value),
        TipoLogradouro: this.formEstagio.get('instEnsinoTipoLogradouro').value,
        UF: this.formEstagio.get('instEnsinoUf').value,
      },
      AgenteIntegracao: {
        Bairro: this.formEstagio.get('agenteIntegracaoBairro').value,
        CNPJ: this.formEstagio.get('agenteIntegracaoCnpj').value,
        Cep: this.formEstagio.get('agenteIntegracaoCep').value,
        Cidade: this.formEstagio.get('agenteIntegracaoMunicipio').value,
        Id: this.formEstagio.get('agenteIntegracaoID').value,
        Logradouro: this.formEstagio.get('agenteIntegracaoLogradouro').value,
        NomeRazao: this.formEstagio.get('agenteIntegracaoRazaoSocial').value,
        Numero: Number(this.formEstagio.get('agenteIntegracaoNumero').value),
        TipoLogradouro: this.formEstagio.get('agenteIntegracaoTipoLogradouro').value,
        UF: this.formEstagio.get('agenteIntegracaoUf').value,
      },
    };
  }
  converteDatas(datas) {
    var [ano, mes, dia] = datas.split('-');
    return `${dia}/${mes}/${ano}`;
  }

  corrigeDatas(datas) {
    var [dia, mes, ano] = datas.split('/');
    return `${ano}-${mes}-${dia}`;
  }

  voltarBusca() {
    const navigationExtras: NavigationExtras = {
      state: {
        usuario: this.navigation,
      },
    };
    this.route.navigate(['/complemento-cadastral/estagio'], navigationExtras);
    this.complementoCadastralService.emitChange({
      tipoComplemento: 'Estágio',
      numord: this.numeroOrdem,
    });
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}
